import React, { Fragment, useEffect, useState } from 'react';

import { toggleLoader } from '@actions/LoaderActions';
import { QueryParams } from '@api/backOffice/fetchResults';
import { DropdownOptionType } from '@components/common/Dropdown';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import { RadioGroup } from '@components/common/RadioGroup';
import ModalComponent from '@components/dev/Components/Modal/Modal';
import { BackOfficeContext, BackOfficeContextType, InputValues } from '@contexts/backoffice-context';
import { ToString } from '@lib/Utils';
import { ActionType, DataTypes, Field, IntegerTypes, ModalSize, OptionsForField, ValueToSet } from '@type/backOfficeModule';
import { IState } from '@type/store';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Base64File from '../Fields/Base64File';
import Base64FileList from '../Fields/Base64FileList';
import DateRange, { DataItem } from '../Fields/DateRange';
import DateTime from '../Fields/DateTime';
import DropdownField from '../Fields/DropdownField';
import DropdownInt from '../Fields/DropdownInt';
import DropdownString from '../Fields/DropdownString';
import Html from '../Fields/Html';
import Int from '../Fields/Int';
import MultiSelectDropdownInt from '../Fields/MultiSelectDropdownInt';
import Paragraph from '../Fields/Paragraph';
import String from '../Fields/String';
import StringList from '../Fields/StringList';
import StringListEdit from '../Fields/StringListEdit';
import useBoHelpers from '../Hooks/useBoHelpers';
import { GetOptionsFromApiPayload, useFieldOptions, UseFieldOptionsProps } from '../Hooks/useFieldOptions';

type ActionModalFormProps = {
  action: ActionType;
  isEditing: boolean;
  setIsEditing: (val: boolean) => void;
  isRowAction: boolean;
  viewFilesValues?: any; //only for row actions
  item?: any; //only for row actions
  setAction?: any; //only for general actions
};

const ActionModalForm = (props: ActionModalFormProps) => {
  const { isRowAction, action, isEditing, item, viewFilesValues, setAction } = props;

  const { config } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const [formValidationErrors, setFormValidationErrors] = useState<any>([]);
  const [values, setValues] = useState<InputValues>({} as InputValues);

  //ModalConfirm Popup
  const [showModalFormConfirmActionPopup, setShowModalFormConfirmActionPopup] = useState<boolean>(false);
  const [modalFormConfirmActionPopupMessage, setModalFormConfirmActionPopupMessage] = useState<string>('');
  const [modalFormConfirmActionPopupActionId, setModalFormConfirmActionPopupActionId] = useState<string>('');

  const { onRowAction } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const { setIsEditing } = props;
  const { getModalSize, checkMultipleSelectValues, displayMessageWithPlaceholder, getMessageToDisplayWithPlaceholder } = useBoHelpers();
  const basePath: any = useSelector<IState>((state) => state.backOffice.basePath);

  //OPTIONS FOR DROPDOWN FIELDS
  const { optionsForField, getOptionsFromApi } = useFieldOptions({
    action: action,
    isEditing: isEditing,
    isRowAction: isRowAction,
    item: item,
    setValues: setValues
  } as UseFieldOptionsProps);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing === false) {
      setValues({} as InputValues);
    } else {
      if (config && action) {
        let allFields: InputValues = {};

        if (isRowAction) {
          action?.formGroups?.forEach((group) => {
            let fields = group?.fields?.reduce(
              (o, current) => ({
                ...o,
                [current.name]:
                  current.dataTypeId === DataTypes.Bool
                    ? item[current.name.charAt(0).toLowerCase() + current.name.slice(1)] === 'True' ||
                      item[current.name.charAt(0).toLowerCase() + current.name.slice(1)] === true
                      ? true
                      : false
                    : item[current.name.charAt(0).toLowerCase() + current.name.slice(1)]
                    ? IntegerTypes.includes(current.dataTypeId)
                      ? Number(item[current.name.charAt(0).toLowerCase() + current.name.slice(1)])
                      : item[current.name.charAt(0).toLowerCase() + current.name.slice(1)]
                    : current.value
                    ? IntegerTypes.includes(current.dataTypeId)
                      ? Number(current.value)
                      : current.value
                    : current.dataTypeId === DataTypes.Base64FileList
                    ? []
                    : ''
              }),
              {} as InputValues
            );
            allFields = { ...allFields, ...fields };
          });
          //set values for field childs
          action?.formGroups?.forEach((group) => {
            let fields: { [key: string]: any } = {};
            group?.fields?.forEach((field: Field) => {
              if (field.itemsConfiguration?.cascadeChild) {
                let child = field.itemsConfiguration?.cascadeChild;
                while (child) {
                  fields[child.name] = child.value
                    ? IntegerTypes.includes(field.dataTypeId)
                      ? Number(child.value)
                      : child.value
                    : IntegerTypes.includes(field.dataTypeId)
                    ? Number(item[child.name.charAt(0).toLowerCase() + child.name.slice(1)])
                    : item[child.name.charAt(0).toLowerCase() + child.name.slice(1)];

                  if (child.itemsConfiguration?.cascadeChild) {
                    child = child.itemsConfiguration?.cascadeChild;
                  } else {
                    //@ts-ignore
                    child = null;
                  }
                }
              }
            });
            allFields = { ...allFields, ...fields };
          });
        } else {
          action?.formGroups?.forEach((group) => {
            let fields = group?.fields?.reduce(
              (o, current) => ({
                ...o,
                [current.name]:
                  current.dataTypeId === DataTypes.Base64FileList
                    ? []
                    : (current.dataTypeId === DataTypes.StringListEdit
                        ? current.value
                          ? current.value
                          : ['']
                        : IntegerTypes.includes(current.dataTypeId)
                        ? Number(current.value)
                        : current.value) ?? ''
              }),
              {} as InputValues
            );
            allFields = { ...allFields, ...fields };
          });
        }

        setValues((prevValues: any) => {
          let newValues = {
            ...prevValues,
            ...allFields
          };
          if (isRowAction) {
            newValues = {
              ...newValues,
              [config.identityColumnName]: item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)]
            };
          }
          return newValues;
        });
      }
    }
  }, [isEditing]);

  //CALL getOptionsFromApi when value for parent field has changed
  useEffect(() => {
    optionsForField.forEach((option: OptionsForField) => {
      if (option.child && option.child !== '' && values[option.field]) {
        let paramValue = option.options.find((op) => op[option.keyFieldName] == values[option.field]);
        paramValue = paramValue ? paramValue[option.childUrlQuerryParameterName] : '';

        //Return if options for parent aleady exists
        if (optionsForField.find((op) => op.field == option.child && op.parentValue == paramValue)) {
          return;
        }

        const params = [
          {
            name: option.childUrlQuerryParameterName as string,
            value: paramValue
          }
        ] as QueryParams[];

        getOptionsFromApi({
          fieldName: option.child,
          keyFieldName: '',
          url: option.childUrl,
          childUrl: '',
          childName: '',
          parentName: option.field,
          childUrlQuerryParameterName: '',
          urlParams: params
        } as GetOptionsFromApiPayload);
      }
    });
  }, [values, optionsForField]);

  const renderField = (field: Field, index: number, groupNumberOfColumnsPerRow: number, parent?: any) => {
    let itemType = field.dataTypeName;
    let itemTypeId = field.dataTypeId as number;
    if (groupNumberOfColumnsPerRow && field.colspan > groupNumberOfColumnsPerRow) {
      field.colspan = groupNumberOfColumnsPerRow;
    }
    let oneColSize = 12 / groupNumberOfColumnsPerRow;
    let colspanValue = field.colspan * oneColSize;

    if (field.isRequired) {
      if (!field.displayName.endsWith('*')) {
        field.displayName = `${field.displayName} *`;
      }
    }
    const child = field.itemsConfiguration?.cascadeChild;
    if (child) {
      if (child.isRequired) {
        if (!child.displayName.endsWith('*')) {
          child.displayName = `${child.displayName} *`;
        }
      }
    }

    switch (itemType) {
      case 'StringList':
        return (
          <Col key={index} md={colspanValue}>
            <StringList
              options={[] as string[]}
              label={field.displayName}
              value={(values[field.name] as string) ?? ''}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              name={field.name}
            />
            {renderError(field)}
          </Col>
        );
      case 'String':
        return (
          <Col key={index} md={colspanValue}>
            <String
              label={field.displayName}
              name={field.name}
              value={(values[field.name] as string) ?? ''}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              mbZero={renderError(field) !== null}
            />
            {renderError(field)}
          </Col>
        );
      case 'MultilineString':
        return (
          <Col key={index} md={colspanValue}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <p className="mb-1">{field.displayName}</p>
              <Form.Control
                as="textarea"
                className={field.rows ? 'h-100' : ''}
                rows={field.rows ? field.rows : 3}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onValueChange({
                    name: field.name,
                    value: event.target.value,
                    itemTypeId
                  } as ValueToSet);
                }}
                value={(values[field.name] as string) ?? ''}
              />
            </Form.Group>
            {renderError(field)}
          </Col>
        );
      case 'DateTime':
        return (
          <Col key={index} md={colspanValue}>
            <DateTime
              label={field.displayName}
              name={field.name}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              mbZero={renderError(field) !== null}
              value={ToString(values[field.name] ? (values[field.name] as string) : '')}
            />
            {renderError(field)}
          </Col>
        );
      case 'Dropdown':
        if (isRowAction) {
          let value: string | number | boolean | string[] = typeof values[field.name] !== 'boolean' ? values[field.name] ?? '' : '';
          value = typeof value === 'boolean' ? '' : value;

          return (
            <Col key={index} md={colspanValue}>
              <DropdownField
                options={field.items as DropdownOptionType[]}
                label={field.displayName}
                itemTypeId={itemTypeId}
                name={field.name}
                value={value}
                onChange={onValueChange}
                isUsedInBackOffice={true}
                displaySearch={true}
                mbZero={renderError(field) !== null}
              />
              {renderError(field)}
            </Col>
          );
        } else {
          return (
            <Col key={index} md={colspanValue}>
              <DropdownField
                options={[] as DropdownOptionType[]}
                label={field.displayName}
                itemTypeId={itemTypeId}
                name={field.name}
                onChange={onValueChange}
                isUsedInBackOffice={true}
                displaySearch={true}
              />
            </Col>
          );
        }
      case 'DateRange':
        return (
          <Col key={index} md={colspanValue}>
            <DateRange
              onChange={onValueChange}
              items={field.items?.map(
                (dateItem: any): DataItem => ({
                  label: dateItem.displayName,
                  itemTypeId: itemTypeId,
                  name: dateItem.name
                })
              )}
              mbZero={renderError(field) !== null}
            />
            {renderError(field)}
          </Col>
        );
      case 'Bool':
        return (
          <Col key={index} md={colspanValue}>
            <RadioGroup
              className="mw-100 mb-4"
              label={field.displayName}
              value={(values[field.name] as boolean) === true ? '1' : '2'}
              itemClassName="bg-eon-red d-inline mr-2 ms-2"
              options={[
                {
                  value: '1',
                  name: 'Da'
                },
                {
                  value: '2',
                  name: 'Nu'
                }
              ]}
              onClick={(value?: string | string[] | number | boolean) => {
                onValueChange({ name: field.name, value: ToString(value), itemTypeId } as ValueToSet);
              }}
            />
            {renderError(field)}
          </Col>
        );
      case 'Date':
        return (
          <Col key={index} md={colspanValue}>
            <DateTime
              label={field.displayName}
              isInModal={true}
              name={field.name}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              value={ToString(values[field.name] ? (values[field.name] as string) : '')}
              mbZero={renderError(field) !== null}
              minValue={field.minValue}
              maxValue={field.maxValue}
            />
            {renderError(field)}
          </Col>
        );
      case 'ReadonlyString':
        return (
          <Col key={index} md={colspanValue}>
            <String
              label={field.displayName}
              name={field.name}
              value={(values[field.name] as string) ?? ''}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              disabled={true}
              mbZero={renderError(field) !== null}
            />
            {renderError(field)}
          </Col>
        );
      case 'DropdownInt':
        return (
          <DropdownInt
            key={index}
            item={field}
            itemTypeId={itemTypeId}
            value={values[field.name] ? values[field.name] : ''}
            onChange={(value) => {
              onValueChange(value);
              const child = field.itemsConfiguration?.cascadeChild ?? null;
              removeChildValueAtParentChange(child);
            }}
            isUsedInFilters={false}
            values={values}
            renderChild={renderField}
            groupNumberOfColumnsPerRow={groupNumberOfColumnsPerRow}
            optionsForField={optionsForField}
            parent={parent}
            colspanValue={colspanValue}
            mbZero={renderError(field) !== null}
          />
        );
      case 'DropdownString':
        return (
          <DropdownString
            key={index}
            item={field}
            itemTypeId={itemTypeId}
            value={values[field.name] ? values[field.name] : ''}
            onChange={(value) => {
              onValueChange(value);
              const child = field.itemsConfiguration?.cascadeChild ?? null;
              removeChildValueAtParentChange(child);
            }}
            isUsedInFilters={false}
            values={values}
            renderChild={renderField}
            groupNumberOfColumnsPerRow={groupNumberOfColumnsPerRow}
            optionsForField={optionsForField}
            parent={parent}
            colspanValue={colspanValue}
          />
        );
      case 'StringListEdit':
        if (!values[field.name] || values[field.name] === '') {
          setValues({
            ...values,
            [field.name]: ['']
          });
        }
        return (
          <StringListEdit
            key={index}
            values={values[field.name] ? (values[field.name] as string[]) : ([''] as string[])}
            label={field.displayName}
            itemTypeId={itemTypeId}
            onChange={onValueChange}
            name={field.name}
            colspan={field.colspan ? field.colspan : groupNumberOfColumnsPerRow ? groupNumberOfColumnsPerRow : 2}
          />
        );
      case 'Int':
        return (
          <Col key={index} md={colspanValue}>
            <Int
              label={field.displayName}
              name={field.name}
              value={values[field.name] ? (values[field.name] as string) : ''}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              mbZero={renderError(field) !== null}
            />
            {renderError(field)}
          </Col>
        );
      case 'Html':
        return (
          <Col key={index} md={colspanValue}>
            <Html
              label={field.displayName}
              name={field.name}
              value={(values[field.name] as string) ?? ''}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
            />
            {renderError(field)}
          </Col>
        );
      case 'Empty':
        return (
          <Col md={12}>
            <div className="m-2 p-3"></div>
          </Col>
        );
      case 'Base64FileList':
        if (isRowAction) {
          let label = field.displayName;

          //pentru fisiere valori masurate se foloseste label dinamic
          if (basePath == 'masterdata/BackofficeMeasuredValuesFiles') {
            label = `Partener: ${item.partnerName} Cod ETSO: ${item.codeETSO}`;
          }
          return (
            <Col key={index} md={colspanValue}>
              <Base64FileList
                label={label}
                name={field.name}
                itemTypeId={itemTypeId}
                onChange={onValueChange}
                value={values[field.name] ? values[field.name] : []}
                viewFilesValues={viewFilesValues}
                allowedFileExtensions={field.allowedFileExtensions}
                allowedMaxTotalFileSizeMB={field.allowedMaxTotalFileSizeMB}
              />
              {renderError(field)}
            </Col>
          );
        } else {
          return (
            <Col key={index} md={colspanValue}>
              <Base64FileList
                label={field.displayName}
                name={field.name}
                itemTypeId={itemTypeId}
                onChange={onValueChange}
                allowedFileExtensions={field.allowedFileExtensions}
                allowedMaxTotalFileSizeMB={field.allowedMaxTotalFileSizeMB}
              />
            </Col>
          );
        }

      case 'Base64File':
        return (
          <Col key={index} md={colspanValue}>
            <Base64File
              label={field.displayName}
              name={field.name}
              itemTypeId={itemTypeId}
              onChange={onValueChange}
              allowedFileExtensions={field.allowedFileExtensions}
              allowedMaxTotalFileSizeMB={field.allowedMaxTotalFileSizeMB}
              mbZero={renderError(field) !== null}
              value={values[field.name]}
            />
            {renderError(field)}
          </Col>
        );
      case 'Paragraph':
        return (
          <Col key={index} md={colspanValue}>
            <Paragraph
              color={field.color}
              fontStyle={field.fontStyle}
              label={field.displayName}
              name={field.name}
              value={(values[field.name] as string) ?? ''}
              hideIfEmtyValue={true}
            />
            {renderError(field)}
          </Col>
        );
      case 'MultiSelectDropdownInt':
        return (
          <MultiSelectDropdownInt
            key={index}
            item={field}
            itemTypeId={itemTypeId}
            value={values[field.name] ? values[field.name] : ''}
            onChange={(value) => {
              onValueChange(value);
              const child = field.itemsConfiguration?.cascadeChild ?? null;
              removeChildValueAtParentChange(child);
            }}
            name={field.name}
            isUsedInFilters={false}
            renderChild={renderField}
            optionsForField={optionsForField}
            parent={parent}
            colspanValue={colspanValue}
          />
        );
      default:
        break;
    }

    return null;
  };

  const renderError = (field: Field) => {
    if (isRowAction) {
      if (item.errors) {
        let errors = JSON.parse(item.errors);

        let err = null;

        errors.map((error: any, idx: number) => {
          if (field.name === error.PropertyName) {
            err = error.Message;
          }
        });

        if (err !== null) {
          return (
            <div className="red fw-bold mb-4" style={{ marginTop: '-12px' }}>
              {err}
            </div>
          );
        } else {
          return err;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const validateValues = (values: any, action: any) => {
    let res = true;
    action.formGroups?.forEach((group: any, index: number) => {
      group.fields?.forEach((field: any, index: number) => {
        const isFieldValid = validateValue(field);
        if (!isFieldValid) {
          res = false;
        }
      });
    });
    return res;
  };

  const validateValue = (field: any): boolean => {
    let res = true;
    let childRes = true;
    if (field.isRequired) {
      if (!values[field.name]) {
        res = false;
        if (!formValidationErrors.find((v: any) => v.name === field.name)) {
          setFormValidationErrors((prevState: any) => [
            ...prevState,
            {
              name: field.name,
              displayName: field.displayName
            }
          ]);
        }
      } else {
        if (formValidationErrors.find((v: any) => v.name === field.name)) {
          setFormValidationErrors((prevState: any) => [...prevState.filter((v: any) => v.name !== field.name)]);
        }
      }
    }

    const child = field.itemsConfiguration?.cascadeChild ?? null;
    if (child) {
      childRes = validateValue(child);
    }
    return res && childRes;
  };

  const removeChildValueAtParentChange = (child: any) => {
    if (child) {
      if (child.dataTypeId == DataTypes.DropdownInt) {
        onValueChange({
          name: child.name,
          value: 0,
          itemTypeId: child.dataTypeId
        });
      } else if (child.dataTypeId == DataTypes.DropdownString) {
        onValueChange({
          name: child.name,
          value: '',
          itemTypeId: child.dataTypeId
        });
      }

      const childChild = child?.itemsConfiguration?.cascadeChild ?? null;
      if (childChild) {
        removeChildValueAtParentChange(childChild);
      }
    }
  };

  const onValueChange = ({ name, value, itemTypeId }: ValueToSet) => {
    let assign: string | number | boolean | string[] | any;
    if (itemTypeId === DataTypes.Bool) {
      assign = value === '1' ? true : false;
    } else {
      assign = IntegerTypes.includes(itemTypeId) ? Number(value) : value;
    }
    setValues((prevValues: any) => {
      return { ...prevValues, [name]: assign };
    });
  };

  return (
    <>
      <ModalComponent
        title={action.displayMessage}
        className="backoffice-modal"
        show={isEditing}
        backdropStatic={true}
        setShow={(state: boolean) => {
          setIsEditing(!isEditing);
        }}
        fullScreen={action.modalSize && action.modalSize == ModalSize.FullScreen ? true : 'md-down'}
        size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
        onClose={() => setFormValidationErrors([])}
        footerAction={
          <Button
            className="btn btn-primary"
            onClick={async (e) => {
              const valid = validateValues(values, action);
              if (valid) {
                if (isRowAction) {
                  //ROW ACTION
                  if (action.showConfirmPopup) {
                    setModalFormConfirmActionPopupMessage(action.confirmMessage ?? 'Sunteți sigur că doriți să efectuați operațiunea?');
                    setModalFormConfirmActionPopupActionId(action.actionId);
                    setShowModalFormConfirmActionPopup(true);
                  } else {
                    if (onRowAction) {
                      dispatch(toggleLoader(true));

                      let success: boolean = await onRowAction(values, action.actionId, action.showApiResponseAsConfirmDialog);
                      if (success === false) {
                      }
                      dispatch(toggleLoader(false));
                    }
                  }
                } else {
                  //GENERAL ACTION
                  if (onRowAction) {
                    dispatch(toggleLoader(true));
                    let success: boolean = await onRowAction(
                      checkMultipleSelectValues(action, values),
                      action.actionId,
                      action.showApiResponseAsConfirmDialog
                    );
                    if (success) {
                      setIsEditing(false);
                      setAction(null);
                    }
                    dispatch(toggleLoader(false));
                  }
                }
              }
            }}
            variant="primary"
          >
            Salveaza
          </Button>
        }
      >
        <Form className="form">
          <Row>
            <Col xs={12} md={12}>
              {action.formGroups?.map((group, groupIndex: number) => {
                return (
                  <Row className="mb-2" key={groupIndex}>
                    {group.displayName && (
                      <Col xs={12}>
                        <h3 className="brand-subtitle">
                          {isRowAction && item
                            ? getMessageToDisplayWithPlaceholder(item, group.displayName)
                            : displayMessageWithPlaceholder(group.displayName)}
                        </h3>
                      </Col>
                    )}
                    {group.fields?.map((field, index: number) => {
                      return (
                        <Fragment key={index}>
                          {field.hint ? (
                            <Fragment>
                              <p className="field_hint">* {field.hint}</p>
                              {renderField(field as Field, index, group.numberOfColumnsPerRow)}
                            </Fragment>
                          ) : (
                            renderField(field as Field, index, group.numberOfColumnsPerRow)
                          )}
                        </Fragment>
                      );
                    })}
                  </Row>
                );
              })}
            </Col>
          </Row>
          {formValidationErrors[0] && (
            <Row>
              <Col xs={12} md={12}>
                <div style={{ color: 'red', fontWeight: 'bold' }}>Câmp obligatoriu {formValidationErrors[0].displayName}</div>
              </Col>
            </Row>
          )}
        </Form>
      </ModalComponent>

      <ChoiceModal
        showModal={showModalFormConfirmActionPopup}
        modalTitle={modalFormConfirmActionPopupMessage}
        options={[
          { id: 'DA', title: 'Da' },
          { id: 'NU', title: 'Nu' }
        ]}
        onClose={async (option: any) => {
          if (option.id == 'DA') {
            if (onRowAction) {
              dispatch(toggleLoader(true));
              //eslint-disable-next-line
              let success: boolean = await onRowAction(values, modalFormConfirmActionPopupActionId);
              dispatch(toggleLoader(false));
            }
            setShowModalFormConfirmActionPopup(false);
          } else if (option.id == 'NU') {
            setShowModalFormConfirmActionPopup(false);
          }
        }}
        onHide={() => {
          setShowModalFormConfirmActionPopup(false);
        }}
        btnWide
      />
    </>
  );
};

export default ActionModalForm;
