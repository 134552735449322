import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faPen, 
    faTriangleExclamation, 
    faTrash, 
    faFile, 
    faEye,
    faPowerOff,
    faCheck,
    faUpload
} from "@fortawesome/free-solid-svg-icons";

const GetActionIcon = (iconName: string) => {
    switch (iconName) {
        case 'icon-edit': return <FontAwesomeIcon className="bo-icon" icon={faPen} />
        case 'icon-delete': return <FontAwesomeIcon className="bo-icon" icon={faTrash} />
        case 'icon-error': return <FontAwesomeIcon className="bo-icon" icon={faTriangleExclamation} />
        case 'icon-doc': return <FontAwesomeIcon className="bo-icon" icon={faFile} />
        case 'icon-details': return <FontAwesomeIcon className="bo-icon" icon={faEye} />
        case 'icon-cancel': return <FontAwesomeIcon className="bo-icon" icon={faPowerOff} />
        case 'icon-confirm': return <FontAwesomeIcon className="bo-icon" icon={faCheck} />
        case 'icon-upload': return <FontAwesomeIcon className="bo-icon" icon={faUpload} />
        default: return <FontAwesomeIcon className="bo-icon" icon={faEye} />
    }
}

export default GetActionIcon;