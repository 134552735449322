import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import AutocitiriClientLogat from '@components/autocitiriClientLogat/AutocitiriClientLogat';

export const AutocitiriUserLogat = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <AutocitiriClientLogat />
        </DelgazWrapper>
    );
};