import { Multiselect } from '@components/common/Multiselect';
import React, { useState, useEffect, useRef } from 'react';
import { DropdownOptionType } from '@components/common/Dropdown';
import { OptionsForField } from './DropdownString';
import { MultiselectCheckBox } from '@components/common/MultiselectCheckBox';
import { Field, ValueToSet } from '@type/backOfficeModule';

type MultiSelectDropdownIntProps = {
  item: Field;
  options?: DropdownOptionType[];
  name?: string;
  label?: string;
  value?: string | number | string[] | any;
  itemTypeId: number;
  isUsedInFilters?: boolean;
  displaySearch?: boolean;
  onChange?: (e: ValueToSet) => void;
  valueWrapper?: (value: string | number) => number | string;
  renderChild?: (field: Field, index: number, groupNumberOfColumnsPerRow: number, parent?: any) => void;
  renderChildFilter?: (filter: Field, parent?: any) => void;
  values?: any;
  groupNumberOfColumnsPerRow?: number;
  optionsForField?: OptionsForField[];
  parent?: Field;
  colspanValue?: number;
  filters?: any;
  hideLabel?: boolean;
  mbZero?: boolean;
  toggled?: boolean;
};

const MultiSelectDropdownInt = ({
  onChange,
  itemTypeId,
  value,
  item,
  name,
  hideLabel,
  mbZero,
  toggled,
  optionsForField
}: MultiSelectDropdownIntProps) => {
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptionType[]>([]);
  const [initialSelectedOptions, setInitialSelectedOptions] = useState<any>(null);
  //eslint-disable-next-line
  const [staticData, setstaticData] = useState<boolean>(item?.itemsConfiguration?.staticData as boolean);
  const multiselectRef = useRef();

  useEffect(() => {
    if (toggled && !item.itemsConfiguration?.showAsRadioButtons) {
      setTimeout(() => {
        //@ts-ignore
        multiselectRef.current?.toggleOptionList();
      }, 50);
    }
  }, [toggled]);

  useEffect(() => {
    if (value && dropdownOptions) {
      const valueArray = JSON.parse(value);
      setInitialSelectedOptions(
        dropdownOptions.filter((dropdownOption: DropdownOptionType) => {
          return valueArray.includes(dropdownOption.id);
        })
      );
    } else {
      setInitialSelectedOptions([]);
    }
  }, [value, dropdownOptions]);

  useEffect(() => {
    if (staticData) {
      setDropdownOptions(getStaticOptions(item));
    } else {
      const keyName = item.itemsConfiguration?.keyFieldName
        ? item.itemsConfiguration?.keyFieldName.charAt(0).toLocaleLowerCase() + item.itemsConfiguration?.keyFieldName.slice(1)
        : 'id';
      const valueName = item.itemsConfiguration?.valueFieldName
        ? item.itemsConfiguration?.valueFieldName.charAt(0).toLocaleLowerCase() + item.itemsConfiguration?.valueFieldName.slice(1)
        : 'value';

      let detailsName = '';

      if (item.itemsConfiguration?.detailsFieldName) {
        detailsName =
          item.itemsConfiguration?.detailsFieldName.charAt(0).toLocaleLowerCase() + item.itemsConfiguration?.detailsFieldName.slice(1);
      }

      if (optionsForField && optionsForField.length > 0) {
        const options = optionsForField
          .find((o) => {
            return o.field === item.name;
          })
          ?.options.map((o: any) => {
            if (detailsName) {
              return {
                id: o[keyName],
                name: o[valueName],
                details: o[detailsName]
              };
            } else {
              return {
                id: o[keyName],
                name: o[valueName]
              };
            }
          });

        setDropdownOptions(options as any);
      }
    }
  }, [item, staticData, parent, optionsForField]);

  const getStaticOptions = (filterItem: Field | any) => {
    let options;
    const keyFieldName: string =
      filterItem?.itemsConfiguration?.keyFieldName?.charAt(0).toLowerCase() + filterItem?.itemsConfiguration?.keyFieldName?.slice(1);
    const valueFieldName: string =
      filterItem?.itemsConfiguration?.valueFieldName?.charAt(0).toLowerCase() + filterItem?.itemsConfiguration?.valueFieldName?.slice(1);
    options = filterItem.items
      ? filterItem.items.map((item: any) => {
          return {
            id: item[keyFieldName],
            name: item[valueFieldName],
            disabled: false
          };
        })
      : [];

    return options;
  };

  return (
    <>
      {item.itemsConfiguration?.showAsRadioButtons ? (
        <MultiselectCheckBox
          options={dropdownOptions}
          initialSelectedOptions={initialSelectedOptions}
          onChange={(selectedOptions: any) => {
            const selectedOptionsMaped = selectedOptions.map((so: any) => so.id);
            const selectedOptionsString = JSON.stringify(selectedOptionsMaped);
            onChange && onChange({ name, value: selectedOptionsString, itemTypeId } as ValueToSet);
          }}
          label={item.displayName}
          name={item.name}
        />
      ) : (
        <Multiselect
          ref={multiselectRef}
          options={dropdownOptions}
          initialSelectedOptions={initialSelectedOptions}
          onChange={(selectedOptions: any) => {
            const selectedOptionsMaped = selectedOptions.map((so: any) => so.id);
            const selectedOptionsString = JSON.stringify(selectedOptionsMaped);
            onChange && onChange({ name, value: selectedOptionsString, itemTypeId } as ValueToSet);
          }}
          label={hideLabel ? '' : item.displayName}
          name={item.name}
          mbZero={mbZero}
        />
      )}
    </>
  );
};

export default MultiSelectDropdownInt;
