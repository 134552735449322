import React from 'react';
import { DataTypes } from '@type/backOfficeModule';
import CautareProgramare from '@components/dev/System/ProgramariCitiri/CautareProgramare';
import { BackOfficeContext, BackOfficeContextType } from '@contexts/backoffice-context';
import ProgramariCitiriContoare from '@components/programariCitiri/ProgramariCitiriContoare';
import useBoHelpers from './Hooks/useBoHelpers';

const PROCESS_TYPE_ID_CITIRI_CONTOARE = 3;

const ColumnValue = ({ column, item }: any) => {
  const { reloadListResults } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const { getColumnOrDetailsValue } = useBoHelpers();

  const renderColumn = () => {
    let col: string = column.name.charAt(0).toLowerCase() + column.name.slice(1);
    let value: string | JSX.Element | any;

    if (column.dataTypeId === DataTypes.HTML && item[col].includes("uid='LipsaAccces'")) {
      value = handleLipsaAcces(col);
    } else {
      value = getColumnOrDetailsValue(column, item);
    }

    return value;
  };

  const handleLipsaAcces = (col: string) => {
    const processTypeId = item['processTypeId'];
    // let edit = item[col].substring(item[col].lastIndexOf('edit='));
    // edit = edit.substring(5, edit.indexOf('&'));
    let contact = item[col].substring(item[col].lastIndexOf('contact='));
    contact = contact.substring(8, contact.indexOf('&'));

    let consumptionPointCode = item[col].substring(item[col].lastIndexOf('consumptionPointCode='));
    consumptionPointCode = consumptionPointCode.substring(21, consumptionPointCode.indexOf('&'));

    let kid = item[col].substring(item[col].lastIndexOf('kid='));
    kid = kid.substring(4, kid.indexOf('"'));

    let linkText = item[col].substring(item[col].indexOf('>'));
    linkText = linkText.substring(1, linkText.indexOf('<'));

    return processTypeId == PROCESS_TYPE_ID_CITIRI_CONTOARE ? (
      <ProgramariCitiriContoare
        kid={kid}
        isUsedInBO={true}
        contactNumber={contact}
        codLocConsum={consumptionPointCode}
        linkText={linkText}
        reloadContent={reloadListResults}
      />
    ) : (
      <CautareProgramare
        kid={kid}
        isUsedInBO={true}
        contactNumber={contact}
        codLocConsum={consumptionPointCode}
        linkText={linkText}
        reloadContent={reloadListResults}
      />
    );
  };

  return <>{renderColumn()}</>;
};

export default React.memo(ColumnValue);
