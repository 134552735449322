import React from 'react';
import { DelgazWrapper } from '@components/global/DelgazWrapper';
import IstoricIndecsiComp from '@components/istoricIndecsiTransmisi/IstoricIndecsiComp';

export const IstoricIndecsi = (): JSX.Element => {
    return (
        <DelgazWrapper>
            <IstoricIndecsiComp />
        </DelgazWrapper>
    );
};