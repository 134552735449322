import React from 'react';
import { ActionType, Column, DataTypes, DetailActionResponse, Field, ModalSize } from '@type/backOfficeModule';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { DownloadFile } from '@api/backOffice/fetchResults';
import Base64FileListReadOnly from '../Fields/Base64FileListReadOnly';
import FrontEndDataViewer from '../Fields/FrontEndDataViewer';
import * as notification from '@lib/Notification';
import { BackOfficeContext, BackOfficeContextType } from '@contexts/backoffice-context';

function useBoHelpers() {
  const { response, searchModel, selected } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const dispatch = useDispatch();

  const getColumnOrDetailsValue = (item: Column | Field, detailObject: DetailActionResponse) => {
    let value: any = '';

    const keyFieldName: string = item?.name?.charAt(0).toLowerCase() + item?.name?.slice(1);
    switch (item.dataTypeId) {
      case DataTypes.ReadonlyString:
        value = <span>{detailObject[keyFieldName]}</span>;
        break;

      case DataTypes.ReadonlyDate:
        value = <span>{detailObject[keyFieldName] ? moment.utc(detailObject[keyFieldName])?.local()?.format('DD.MM.YYYY') : '-'}</span>;
        break;

      case DataTypes.ReadonlyDateTime:
        value = (
          <span>{detailObject[keyFieldName] ? moment.utc(detailObject[keyFieldName])?.local()?.format('DD.MM.YYYY HH:mm') : '-'}</span>
        );
        break;

      case DataTypes.UrlList:
        value = '';
        if (Array.isArray(detailObject[keyFieldName])) {
          value = detailObject[keyFieldName].map(
            (
              urlItem: {
                title: string;
                url: string;
                isInternal: boolean;
                extension: string;
                fileName: string;
              },
              index: number
            ) => {
              if (urlItem.isInternal) {
                return <div key={index}>{getUrlInternal(urlItem)}</div>;
              } else
                return (
                  <div key={index}>
                    <a href={urlItem.url}>{urlItem.title}</a>
                  </div>
                );
            }
          );
        }
        if (Array.isArray(value) && value.length == 0) {
          value = null;
        }
        break;

      case DataTypes.Date:
        value = <span>{detailObject[keyFieldName] ? moment.utc(detailObject[keyFieldName])?.local()?.format('DD.MM.YYYY') : '-'}</span>;
        break;

      case DataTypes.DateTime:
        value = (
          <span>{detailObject[keyFieldName] ? moment.utc(detailObject[keyFieldName])?.local()?.format('DD.MM.YYYY HH:mm:ss') : '-'}</span>
        );
        break;

      case DataTypes.HTML:
        value = <div dangerouslySetInnerHTML={{ __html: detailObject[keyFieldName] }} />;
        break;

      case DataTypes.Url:
        if (!detailObject[keyFieldName]) return;
        if (detailObject[keyFieldName]?.isInternal) {
          value = getUrlInternal(detailObject[keyFieldName]);
        } else {
          value = <a href={detailObject[keyFieldName]?.url}>{detailObject[keyFieldName]?.title}</a>;
        }
        break;

      case DataTypes.Bool:
        value = detailObject[keyFieldName] ? 'Da' : 'Nu';
        break;

      case DataTypes.Base64FileList:
        value = (
          <Base64FileListReadOnly
            label={''}
            viewFilesValues={item.value.map((item: any) => {
              return {
                blobName: item.fileName,
                originalFileName: item.fileName,
                documentUrl: item.fileDownloadUrl
              };
            })}
          />
        );
        break;

      case DataTypes.StringList:
        value = '';
        if (detailObject[keyFieldName] && detailObject[keyFieldName].length > 0) {
          if (detailObject[keyFieldName].length == 1) {
            value = detailObject[keyFieldName][0];
          } else {
            value = detailObject[keyFieldName].map((item: string, index: number) => (
              <p key={index} className="m-0">
                {item};
              </p>
            ));
          }
        }
        break;

      case DataTypes.FrontEndDataViewer:
        if (detailObject[keyFieldName]) {
          value = (
            <FrontEndDataViewer
              footer={detailObject[keyFieldName].footer}
              header={detailObject[keyFieldName].header}
              tableGridStructure={detailObject[keyFieldName].tableGridStructure}
            />
          );
        }
        break;

      default:
        value = detailObject[keyFieldName];
        break;
    }

    if (value) {
      return value;
    } else if (item.hideIfEmptyValue) {
      return '';
    } else if (item.messageForEmptyValue) {
      return item.messageForEmptyValue;
    } else if (value == 0) {
      return value;
    } else {
      return '-';
    }
  };

  const getUrlInternal = (
    urlItem:
      | any
      | {
          title: string;
          url: string;
          isInternal: boolean;
          extension: string;
          fileName: string;
        }
  ) => {
    return (
      <a
        href="#!"
        onClick={async () => {
          dispatch(toggleLoader(true));
          let res = '' as BlobPart;
          let isError = false;
          res = await DownloadFile(urlItem?.url, true).catch((error) => {
            isError = true;
            dispatch(toggleLoader(false));
            try {
              const decoder = new TextDecoder('utf-8');
              const decodedString = decoder.decode(error);
              const obj = JSON.parse(decodedString);
              const status = obj.status;
              if (status == 401) {
                notification.error('Sesiunea a expirat. Pagina se va reâncărca automat');
                setTimeout(() => {
                  window.location.reload();
                }, 5000);
              } else {
                notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
              }
            } catch (error) {
              notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
            }
          });
          if (!isError) {
            try {
              setTimeout(() => {
                const blob: Blob = new Blob([res]);
                const fileName: string = urlItem?.fileName
                  ? `${urlItem?.fileName}-${moment().format('YYMMDDHms')}${urlItem?.extension}`
                  : `Fisier-${moment().format('YYMMDDHms')}.pdf`;
                const objectUrl: string = URL.createObjectURL(blob);
                const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

                a.href = objectUrl;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(objectUrl);
                dispatch(toggleLoader(false));
              }, 500);
            } catch (error) {
              dispatch(toggleLoader(false));
            }
          }
        }}
      >
        {urlItem?.title}
      </a>
    );
  };

  const downloadFileAction = async (action: ActionType, item?: any) => {
    let urlParams;
    if (action.redirectQueryParametes && item) {
      urlParams = getUrlParamsStringForRequestedQueryParams(action.redirectQueryParametes, item);
    }

    if (action.actionTypeName == 'DownloadInternalAction' || action.actionTypeName == 'DownloadInternal') {
      dispatch(toggleLoader(true));
      let res = '' as BlobPart;
      let isError = false;
      res = await DownloadFile(
        urlParams ? action.redirectUrl + urlParams : action.redirectUrl,
        true,
        action.actionTypeName == 'DownloadInternal'
      ).catch((error) => {
        isError = true;
        dispatch(toggleLoader(false));
        try {
          const decoder = new TextDecoder('utf-8');
          const decodedString = decoder.decode(error);
          const obj = JSON.parse(decodedString);
          const status = obj.status;
          if (status == 401) {
            notification.error('Sesiunea a expirat. Pagina se va reâncărca automat');
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } else {
            notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
        } catch (error) {
          notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });
      if (!isError) {
        try {
          setTimeout(() => {
            const blob: Blob = new Blob([res]);
            const fileName: string = action.downloadFileName;
            const objectUrl: string = URL.createObjectURL(blob);
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

            a.href = objectUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            URL.revokeObjectURL(objectUrl);
            dispatch(toggleLoader(false));
          }, 500);
        } catch (error) {
          dispatch(toggleLoader(false));
        }
      }
    } else if (action.actionTypeName == 'DownloadExternal') {
      window.location.href = urlParams ? action.redirectUrl + urlParams : action.redirectUrl;
    }
  };

  const getUrlParamsStringForRequestedQueryParams = (redirectQueryParametes: string | any, item: any) => {
    let queryParamsString = '';
    if (redirectQueryParametes) {
      let requestedParams = redirectQueryParametes
        .replaceAll('[', '')
        .replaceAll(']', '')
        .split(',')
        .map((redirectQueryParam: string) => {
          redirectQueryParam = redirectQueryParam.trim();
          return redirectQueryParam.charAt(0).toLowerCase() + redirectQueryParam.slice(1);
        });

      requestedParams.forEach((requestedParamName: string) => {
        queryParamsString += queryParamsString.length > 1 ? '&' : '?';
        queryParamsString += `${requestedParamName}=${item[requestedParamName] ?? ''}`;
      });
    }
    return queryParamsString;
  };

  const getModalSize = (size: number) => {
    if (size == ModalSize.Small) {
      return 'sm';
    } else if (size == ModalSize.Medium) {
      return 'lg';
    } else if (size == ModalSize.Large) {
      return 'xl';
    }
  };

  const getMessageToDisplayWithPlaceholder = (item: any, displayMessage: string) => {
    const regex = /{%(.*?)%}/g;

    return displayMessage.replace(regex, (match, placeholder) => {
      return item[placeholder.charAt(0).toLowerCase() + placeholder.slice(1)] || '';
    });
  };

  const checkMultipleSelectValues = (action: ActionType, values: any) => {
    if (action.allowMultipleSelect) {
      let Ids: number[] = [];

      selected.map((item: any) => Ids.push(item.id));

      if (action.actionTypeName === 'ModalForm') {
        return { ...values, Ids: Ids };
      } else {
        return { Ids: Ids };
      }
    } else if (action.actionId.includes('Filtered')) {
      return { Filters: searchModel.Filters, ...values };
    } else {
      return values;
    }
  };

  const displayMessageWithPlaceholder = (displayMessage: string) => {
    const regex = /{%(.*?)%}/g;

    return displayMessage.replace(regex, (match, placeholder: string) => {
      if (placeholder === 'selectedRows') {
        return selected.length.toString();
      }

      if (placeholder === 'totalRows') {
        return response?.metadata?.totalItemsCount?.toString() || '';
      }

      if (placeholder.includes('sum')) {
        const sumMatch = placeholder.match(/sum\(([^)]+)\)/);
        if (sumMatch) {
          const sumKey = sumMatch[1].charAt(0).toLowerCase() + sumMatch[1].slice(1);
          const sum = selected.reduce((acc, item) => acc + (item[sumKey] || 0), 0);
          return sum.toString();
        }
        return '';
      }

      if (placeholder.includes('footer')) {
        const footerMatch = placeholder.match(/footer\(([^)]+)\)/);
        if (footerMatch) {
          const footerItem = response?.metadata?.footerTotals?.find((item: any) => item.columnName === footerMatch[1]);
          return footerItem ? footerItem.value.toString() : '';
        }
        return '';
      }

      return match;
    });
  };

  return {
    getColumnOrDetailsValue,
    downloadFileAction,
    getUrlParamsStringForRequestedQueryParams,
    getModalSize,
    getMessageToDisplayWithPlaceholder,
    checkMultipleSelectValues,
    displayMessageWithPlaceholder
  };
}

export default useBoHelpers;
