import {store} from '@lib/store';
import axios from 'axios';
import {Proxy, proxy} from '@api/azureProxy';

export function getConsumptionPoints(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/self-reading/SelfReadings/consumption_points_user?utility=${payload.utility || 1}&pod=${payload.pod || ''}&clc=${payload.clc || ''}&clientName=${payload.clientName || ''}&startDate=${payload.startDate || ''}&endDate${payload.endDate || ''}&pageNumber=${payload.pageNumber || ''}&pageSize=${payload.pageSize || ''}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function getConsumptionPointDetails(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/self-reading/SelfReadings/consumption_point_details_user?utility=${payload.utility || 1}&consumptionPointId=${payload.id}&clc=${payload.clc}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function save(payload: any, utility: number) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: `/self-reading/SelfReadings/save_selfreading_user?utility=${utility || 1}`,
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
        }
    });
}

export function deleteIndex(payload: any) {
    const state = store.getState();

    const proxyData: Proxy = {
        method: 'POST',
        endPoint: `/self-reading/SelfReadings/delete_selfreading_user?utility=${payload.utility || 1}`,
        data: payload,
        withAuthBearer: true,
    };

    return proxy(proxyData, {
        headers: {
            'Authorization': 'Bearer ' + state.auth.token,
            'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
        }
    });
}