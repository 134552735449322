import React, { useEffect, useState } from 'react';
import ConsumptionPoints from './ConsumptionPoints';
import { deleteIndex, getConsumptionPointDetails, getConsumptionPoints } from '@api/autocitiri/autocitiriClientLogat';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import ModalAutocitiriClientLogat from './ModalAutocitiriClientLogat';
import { Modal, Button } from 'react-bootstrap';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';
import Pagination from '@components/common/Pagination/Pagination';
import { Input } from '@components/common/Input';
import ButtonFilters from '@components/common/Button/ButtonFilters';
import { IState } from '@type/store';
import { useSelector } from 'react-redux';
import { getClientPortofolio } from '@api/user/user';
import MaskedInput from '@components/common/Input/MaskedInput';
import { UTILITY_EE } from '@constants/Utility';
import moment from 'moment';

const AutocitiriClientLogat = () => {
  const [list, setList] = useState([]);
  const [data, setData] = useState<any>(null);
  const [id, setId] = useState<number | null>(null);
  const [clc, setClc] = useState<string | null>('');
  const [show, setShow] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [utility, setUtility] = useState(1);
  const [searchPod, setSearchPod] = useState('');
  const [searchClc, setSearchClc] = useState('');
  const [clientName, setClientName] = useState('');
  const [toggleFilters, setToggleFilters] = useState(false);
  const [hasCP, setHasCP] = useState(false);
  const [error, setError] = useState<string>('');
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isLastDay, setIsLastDay] = useState<boolean>(false);
 
  const SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;

  const dispatch = useDispatch();

  useEffect(() => {
    getPortofolio(utility);
    getCPList({
      utility: utility,
      pod: searchPod,
      clc: searchClc,
      clientName: clientName,
      startDate: '',
      endDate: '',
      pageNumber: pageNumber,
      pageSize: pageSize
    });
  }, [pageSize, pageNumber]);

  const getCPList = async (payload?: any) => {
    dispatch(toggleLoader(true));

    let defaultPayload = {
      utility: utility,
      pod: searchPod,
      clc: searchClc,
      clientName: clientName,
      startDate: '',
      endDate: '',
      pageNumber: pageNumber,
      pageSize: pageSize
    };

    await getConsumptionPoints(payload ? payload : defaultPayload)
      .then((response) => {
        if (response && response.data) {
          setList(response.data.items);
          setTotalItems(response.data.metadata.totalItemsCount);
          setPageNumber(response.data.metadata.pageIndex);
          setPageSize(response.data.metadata.pageSize);
        }
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const getPortofolio = async (utility: number) => {
    dispatch(toggleLoader(true));

    await getClientPortofolio({ pageSize: 1, utility: utility })
      .then((response) => {
        if (response && response.items && response.items.length > 0) {
          setHasCP(true);
        } else {
          setHasCP(false);
        }
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const getData = async (id: number, clc: string) => {
    dispatch(toggleLoader(true));
    setError('');
    setData(null);
    setId(id);
    setClc(clc);

    await getConsumptionPointDetails({ utility, id, clc }).then((response: any) => {
      if (response && response.data && response.data.devices && response.data.devices.length > 0) {
        setData(response.data);
        setShow(true);
      } else if (response && response.data && !response.data.devices) {
        setError('Există deja autocitire transmisă pentru locul de consum selectat');
      } else {
        setError('A apărut o eroare tehnică. Vă rugam reveniți mai târziu.');
      }
    });

    dispatch(toggleLoader(false));
  };

  //eslint-disable-next-line
  const deleteSentIndex = async () => {
    dispatch(toggleLoader(true));
    await deleteIndex({ consumptionPointId: id, consumptionPointCode: clc, utility: utility }).then((response: any) => {
      if (response && response.success && response.success === true) {
        setShowConfirmDeleteModal(false);
        setShowSuccessDeleteModal(true);
        getCPList();
      }
    });

    dispatch(toggleLoader(false));
  };

  const renderConfirmDeleteModal = () => {
    return (
      <Modal
        show={showConfirmDeleteModal}
        onHide={() => {
          setShowConfirmDeleteModal(false);
        }}
        centered
        backdrop={'static'}
      >
        <Modal.Header closeButton>
          <div>
            <h5 className="red">Confirmare</h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <span>Dorești să ștergi indexul transmis?</span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              deleteSentIndex();
            }}
          >
            Da
          </Button>
          <Button
            onClick={() => {
              setShowConfirmDeleteModal(false);
            }}
          >
            Nu
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderSuccessModal = () => {
    return (
      <Modal
        show={showSuccessModal}
        onHide={() => {
          setShowSuccessModal(false);
        }}
        centered
        backdrop={'static'}
      >
        <Modal.Header closeButton>
          <div>
            <h5 className="red">Succes!</h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          {!isLastDay ? <span>
            Autocitirea a fost transmisă cu succes. Ai posibilitatea să editezi indexul în următoarele 24 de ore sau până la sfârșitul
            perioadei de autocitire.
          </span> : <span>Autocitirea a fost transmisă cu succes. Ai posibilitatea să editezi indexul astăzi, până la ora 23:00.</span>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowSuccessModal(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderInfoModal = () => {
    return <Modal
        show={showInfoModal}
        onHide={() => {
            setShowInfoModal(false);
            setShowSuccessModal(true);
        }}
        centered
        backdrop={'static'}
    >
        <Modal.Header closeButton>
            <div>
                <h5 className="red">Informare</h5>
            </div>
        </Modal.Header>
        <Modal.Body>
            <span>Locul de consum este planificat pentru citire de către operatorul de distribuție. </span>
            <span>Indexul tău va fi luat în considerare în cazul în care nu avem citire OD.</span>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => {
                setShowInfoModal(false);
                setShowSuccessModal(true);
            }}>OK</Button>
        </Modal.Footer>
    </Modal>;
  };

  const renderSuccessDeleteModal = () => {
    return (
      <Modal
        show={showSuccessDeleteModal}
        onHide={() => {
          setShowSuccessDeleteModal(false);
        }}
        centered
        backdrop={'static'}
      >
        <Modal.Header closeButton>
          <div>
            <h5 className="red">Succes!</h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <span>Indexul transmis a fost șters.</span>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowSuccessDeleteModal(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleSelect = (e: any) => {
    if (e === '1') {
      setUtility(1);
    } else {
      setUtility(2);
    }

    setToggleFilters(false);
    setSearchPod('');
    setSearchClc('');
    setClientName('');
    setPageNumber(1);
    setPageSize(10);
    getPortofolio(e === '1' ? 1 : 2);
    getCPList({ utility: e, pod: '', clc: '', clientName: '', startDate: '', endDate: '', pageNumber: 1, pageSize: 10 });
  };

  const setElementsPerPage = (elements: number) => {
    setPageSize(elements);
  };

  const paginate = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const renderFilters = () => {
    return (
      <div className="m-4">
        <Row className="justify-content-center">
          <Col xs={12} sm={4}>
            <Input value={clientName} label="Nume client" onChange={(e) => setClientName(e.target.value)} />
          </Col>
          <Col xs={12} sm={4}>
            <MaskedInput
              value={searchPod}
              mbZero
              onChange={(e: any) => {
                const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                setSearchPod(currentValue);
              }}
              utility={utility == UTILITY_EE ? 'EE' : 'GN'}
              label="POD"
              mask={'0123333333'}
            />
          </Col>
          <Col xs={12} sm={4}>
            <MaskedInput
              value={searchClc}
              mbZero
              onChange={(e: any) => {
                const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                setSearchClc(currentValue);
              }}
              utility={utility == UTILITY_EE ? 'EE' : 'GN'}
              isClc
              label="Cod loc consum"
              mask={'0123333333'}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col xs={12} sm={9}>
            <div className="triplet-separator"></div>
          </Col>
        </Row>
        <Row>
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => {
                setPageNumber(1);

                getCPList({
                  utility: utility,
                  pod: searchPod,
                  clc: searchClc,
                  clientName: clientName,
                  startDate: '',
                  endDate: '',
                  pageNumber: 1,
                  pageSize: pageSize
                });
              }}
            >
              Caută
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => {
                setSearchPod('');
                setSearchClc('');
                setClientName('');
                setPageNumber(1);
                setPageSize(10);

                getCPList({
                  utility: utility,
                  pod: '',
                  clc: '',
                  clientName: '',
                  startDate: '',
                  endDate: '',
                  pageNumber: 1,
                  pageSize: 10
                });
              }}
            >
              Reset
            </Button>
          </div>
        </Row>
      </div>
    );
  };

  const areFiltersEmpty = () => {
    if (clientName === '' && searchPod === '' && searchClc === '') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <Tabs defaultActiveKey={1} className="my-account-tabs" mountOnEnter={true} unmountOnExit={true} onSelect={handleSelect}>
        <Tab eventKey={1} title="Energie Electrică" mountOnEnter={true} unmountOnExit={true}>
          <div className="d-flex justify-content-end mb-2 mt-2">
            <ButtonFilters onClick={() => setToggleFilters((prev) => !prev)} toggled={toggleFilters} />
          </div>
          {toggleFilters && renderFilters()}
          <ConsumptionPoints
            list={list}
            getData={getData}
            deleteIndex={(id: number, clc: string) => {
              setId(id);
              setClc(clc);
              setShowConfirmDeleteModal(true);
            }}
          />

          {!hasCP && (
            <>
              <p className="mt-3 mb-0">Momentan, în contul tău online nu sunt alocate locuri de consum.</p>
              <p>
                Poți adăuga noi parteneri și locuri de consum, pentru o mai bună gestionare a contului și pentru a avea la îndemână
                detaliile tehnice ale locurilor de consum.
              </p>
              <Button onClick={() => (window.location.href = `${SITE_URL}/parteneri-locuri-de-consum`)}>Adaugă loc de consum</Button>
            </>
          )}

          {hasCP && list.length === 0 && areFiltersEmpty() && (
            <>
              <p className="mt-3 mb-0">
                Perioada de transmitere a indexului s-a încheiat. Te vom anunța când se deschide perioada de autocitiri printr-o notificare
                pe care o vei găsi în secțiunea Notificări.
              </p>
            </>
          )}
          {hasCP && list.length === 0 && !areFiltersEmpty() && (
            <>
              <p className="mt-3 mb-0">Nu există rezultate conform criteriilor de căutare aplicate.</p>
            </>
          )}

          {list.length > 0 && (
            <Pagination
              currentPage={pageNumber}
              totalElements={totalItems}
              perPage={pageSize}
              paginate={paginate}
              setElementsPerPage={setElementsPerPage}
            />
          )}
        </Tab>
        <Tab eventKey={2} title="Gaze Naturale" mountOnEnter={true} unmountOnExit={true}>
          <div className="d-flex justify-content-end mb-2 mt-2">
            <ButtonFilters onClick={() => setToggleFilters((prev) => !prev)} toggled={toggleFilters} />
          </div>
          {toggleFilters && renderFilters()}
          <ConsumptionPoints
            list={list}
            getData={getData}
            deleteIndex={(id: number, clc: string) => {
              setId(id);
              setClc(clc);
              setShowConfirmDeleteModal(true);
            }}
          />

          {!hasCP && (
            <>
              <p className="mt-3 mb-0">Momentan, în contul tău online nu sunt alocate locuri de consum.</p>
              <p>
                Poți adăuga noi parteneri și locuri de consum, pentru o mai bună gestionare a contului și pentru a avea la îndemână
                detaliile tehnice ale locurilor de consum.
              </p>
              <Button onClick={() => (window.location.href = `${SITE_URL}/parteneri-locuri-de-consum`)}>Adaugă loc de consum</Button>
            </>
          )}

          {hasCP && list.length === 0 && areFiltersEmpty() && (
            <>
              <p className="mt-3 mb-0">
                Perioada de transmitere a indexului s-a încheiat. Te vom anunța când se deschide perioada de autocitiri printr-o notificare
                pe care o vei găsi în secțiunea Notificări.
              </p>
            </>
          )}
          {hasCP && list.length === 0 && !areFiltersEmpty() && (
            <>
              <p className="mt-3 mb-0">Nu există rezultate conform criteriilor de căutare aplicate.</p>
            </>
          )}

          {list.length > 0 && (
            <Pagination
              currentPage={pageNumber}
              totalElements={totalItems}
              perPage={pageSize}
              paginate={paginate}
              setElementsPerPage={setElementsPerPage}
            />
          )}
        </Tab>
      </Tabs>
      {data !== null && (
        <ModalAutocitiriClientLogat
          show={show}
          handleShow={(value: boolean) => setShow(value)}
          modalData={data}
          clearModalData={() => setData(null)}
          handleSuccessModal={(value: boolean) => {
            if (data.plannedMonths) {
              if (data.plannedMonths === 'lunar') {
                setShowInfoModal(value);
              } else if (data.plannedMonths.includes((moment().month() + 1).toString())) {
                setShowInfoModal(value);
              } else {
                setShowSuccessModal(value);
              }
            } else {
                setShowSuccessModal(value);
            }

            if (value === true) {
              getCPList();
              setClc('');
              setId(null);
            }
          }}
          consumptionPointId={id}
          consumptionPointCode={clc}
          setIsLastDay={(value: boolean) =>  setIsLastDay(value)}
        />
      )}

      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}

      {renderInfoModal()}
      {renderSuccessModal()}
      {renderConfirmDeleteModal()}
      {renderSuccessDeleteModal()}
    </div>
  );
};

export default AutocitiriClientLogat;