import { toggleLoader } from '@actions/LoaderActions';
import { getSelfReadingsHistory, getSelfReadingsHistoryDetails } from '@api/selfReadingHistory';
import ButtonFilters from '@components/common/Button/ButtonFilters';
import DatePicker from '@components/common/Date/DatePicker';
import MaskedInput from '@components/common/Input/MaskedInput';
import Pagination from '@components/common/Pagination/Pagination';
import { UTILITY_EE } from '@constants/Utility';
import {
  ConsumptionPointHistoryDetailsUserResponse,
  HistorySelfReadingsUserItem,
  HistorySelfReadingsUserResponse
} from '@type/selfReadings';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import TableItem from './TableItem';

const IstoricIndecsiTransmisi = (props: { utility: number }) => {
  const [list, setList] = useState<HistorySelfReadingsUserItem[]>([]);
  const [toggleFilters, setToggleFilters] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [pod, setPod] = useState('');
  const [clc, setClc] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { utility } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    getList({ utility, pageNumber, pageSize, pod, clc, startDate, endDate });
  }, [pageNumber, pageSize]);

  const setElementsPerPage = (elements: number) => {
    setPageSize(elements);
  };

  const paginate = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const getList = async (payload: any) => {
    dispatch(toggleLoader(true));

    await getSelfReadingsHistory(payload)
      .then((res: any) => {
        let data: HistorySelfReadingsUserResponse = res.data;
        if (data && data.items) {
          setList(
            data.items.map((item: HistorySelfReadingsUserItem) => {
              return { ...item, isCollapsed: true };
            })
          );
          setTotalItems(res.data.metadata.totalItemsCount);
          setPageNumber(res.data.metadata.pageIndex);
          setPageSize(res.data.metadata.pageSize);
        }
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const getDetails = async (id: number) => {
    dispatch(toggleLoader(true));

    await getSelfReadingsHistoryDetails({ utility: utility, consumptionPointId: id })
      .then((response) => {
        if (response && response.data && response.status === 200) {
          let consumptionPointHistoryDetailsUserResponse: ConsumptionPointHistoryDetailsUserResponse = response.data;

          setList([
            ...list.map((item: HistorySelfReadingsUserItem) => {
              if (item.consumptionPointId === id) {
                item = { ...item, devices: [...consumptionPointHistoryDetailsUserResponse.devices] };
              }
              return item;
            })
          ]);
        }
      })
      .catch((err) => console.log(err));

    dispatch(toggleLoader(false));
  };

  const handleIsCollapsed = (id: number, value: boolean) => {
    let items = list;

    items.map((item: any, index: number) => {
      if (item.consumptionPointId === id) {
        item.isCollapsed = value;
      }
    });

    setList([...items]);
  };

  const renderFilters = () => {
    return (
      <div className="m-4">
        <Row className="justify-content-center">
          <Col xs={12} sm={3}>
            <MaskedInput
              value={pod}
              mbZero
              onChange={(e: any) => {
                const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                setPod(currentValue);
              }}
              utility={utility == UTILITY_EE ? 'EE' : 'GN'}
              label="POD"
              mask={'0123333333'}
            />
          </Col>
          <Col xs={12} sm={3}>
            <MaskedInput
              value={clc}
              mbZero
              onChange={(e: any) => {
                const currentValue = (e.target.value ? e.target.value.toLocaleUpperCase() : '') as string;
                setClc(currentValue);
              }}
              utility={utility == UTILITY_EE ? 'EE' : 'GN'}
              isClc
              label="Cod loc consum"
              mask={'0123333333'}
            />
          </Col>
          <Col xs={12} sm={3} className="mb-3">
            <DatePicker
              label="Dată început"
              name="Dată început"
              selected={startDate ? moment(startDate).toDate() : startDate}
              onChange={(e: any) => {
                let date = moment(e).format('YYYY-MM-DD') || '';
                setStartDate(date);
              }}
              mbZero
            />
          </Col>
          <Col xs={12} sm={3} className="mb-3">
            <DatePicker
              label="Dată sfârșit"
              name="Dată sfârșit"
              selected={endDate ? moment(endDate).toDate() : endDate}
              onChange={(e: any) => {
                let date = moment(e).format('YYYY-MM-DD') || '';
                setEndDate(date);
              }}
              minDate={startDate && startDate}
              mbZero
            />
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col xs={12} sm={9}>
            <div className="triplet-separator"></div>
          </Col>
        </Row>
        <Row>
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => {
                setPageNumber(1);
                setPageSize(10);

                getList({ utility, pageNumber: 1, pageSize: 10, pod, clc, startDate, endDate });
              }}
            >
              Caută
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              onClick={() => {
                setPod('');
                setClc('');
                setStartDate('');
                setEndDate('');
                setPageNumber(1);
                setPageSize(10);

                getList({ utility: utility, pageNumber: 1, pageSize: 10, pod: '', clc: '', startDate: '', endDate: '' });
              }}
            >
              Reset
            </Button>
          </div>
        </Row>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-end mb-2 mt-2">
        <ButtonFilters onClick={() => setToggleFilters((prev) => !prev)} toggled={toggleFilters} />
      </div>
      {toggleFilters && renderFilters()}
      <div className="general-table-container">
        <table className="w-100">
          <thead>
            <tr>
              <th>
                <div>Nume client</div>
              </th>
              <th>
                <div>Adresa locului de consum</div>
              </th>
              <th>
                <div>POD</div>
              </th>
              <th>
                <div>CLC</div>
              </th>
              <th>
                <div>Data transmiterii</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 &&
              list.map((item: any, index: number) => (
                <TableItem item={item} key={index} handleIsCollapsed={handleIsCollapsed} getDetails={getDetails} utility={utility} />
              ))}
          </tbody>
        </table>
        {list.length === 0 && <p className="text-center mt-2">Nu există date disponibile.</p>}
      </div>
      {list.length > 0 && (
        <Pagination
          currentPage={pageNumber}
          totalElements={totalItems}
          perPage={pageSize}
          paginate={paginate}
          setElementsPerPage={setElementsPerPage}
        />
      )}
    </div>
  );
};

export default IstoricIndecsiTransmisi;