import React, { useEffect, useRef, useState } from 'react';
import * as notification from '@lib/Notification';
import { IState } from '@type/store';
import { useDispatch, useSelector } from 'react-redux';
import { setPath } from '@actions/BoActions';
import { GetConfig, ActionSave, GetResults } from '@api/backOffice/fetchResults';
import { toggleLoader } from '@actions/LoaderActions';
import { ActionType, BackOffice, BackOfficeSearch, DataTypes, DetailActionResponse, Filter, ValueToSet } from '@type/backOfficeModule';
import moment from 'moment';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import { useInView } from 'react-intersection-observer';

export type MetaData = {
  pageIndex: number;
  pageSize: number;
  totalItemsCount: number;
  footerTotals?: any;
};

export type ResponseType = {
  items: Array<any>;
  metadata?: MetaData;
};

export type DetailedElement = {
  [key: string]: string | any;
};

export type BackOfficeContextType = {
  config?: BackOffice | null;
  onRowAction?: (values: InputValues, actionId: string, showResponseAsConfirmDialog?: boolean) => Promise<boolean>;
  fetchDetails?: (values: InputValues, actionId: string) => any;
  response?: ResponseType | null;
  fetchResults: (searchModel: BackOfficeSearch, includeFilters: boolean) => void;
  isFullWidth: boolean;
  detailedElement?: DetailedElement | null;
  updateDetailedElement: (item: DetailedElement) => void;
  setSearchModel: (searchModel: BackOfficeSearch) => void;
  searchModel: BackOfficeSearch;
  reloadListResults: () => void;
  onFilterValueChange: ({ name, value, itemTypeId }: ValueToSet) => void;
  requiredFilterNotFound: boolean;
  selected: any[];
  updateSelectedElement: (item: DetailedElement) => void;
  clearSelected: any;
  updateAllSelectedElements: (items: DetailedElement[]) => void;
  isFilterInactive: any;
  conditionalConfig?: BackOffice | null;
  isGeneralActionValidationTrue?: any;
  footerRef?: any;
  isFooterVisible?: boolean;
  headerRef?: any;
  isHeaderVisible?: boolean;
};

export type InputValues = {
  [key: string]: string | boolean | number | string[];
};

export const BackOfficeContext = React.createContext<BackOfficeContextType | null>({} as BackOfficeContextType);

type Props = {
  children: JSX.Element;
  baseUrl: string;
  securityContext: string;
};

export type ColumnType = {
  displayName: string;
  name: string;
  dataTypeId: number;
  showInTable: boolean;
  columnSize: string | any;
};

const BackOfficeProvider = ({ children, baseUrl, securityContext }: Props) => {
  const [config, setConfig] = useState<BackOffice | null>(null);
  const [isFullWidth, setIsFullWidth] = useState<boolean>(true);
  const [response, setResponse] = useState<ResponseType | null>(null);
  const [detailedElement, setDetailedElement] = useState<DetailedElement | null>(null);
  const [searchModel, setSearchModel] = useState<BackOfficeSearch>(new BackOfficeSearch());
  const [requiredFilters, setRequiredFilters] = useState<string[]>([]);
  const [requiredFilterNotFound, setRequiredFilterNotFound] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);

  //modal row action response
  const [showModalRowActionResponse, setShowModalRowActionResponse] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalMessage, setModalMessage] = useState<string>('');

  const basePath: string = useSelector<IState>((state) => state.backOffice.basePath) as string;
  const dispatch = useDispatch();

  const [conditionalConfig, setConditionalConfig] = useState<BackOffice | null>(null);
  const [isFooterVisible, setIsFooterVisible] = useState<boolean>(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setDetailedElement(null);
    };
  }, []);

  useEffect(() => {
    if (baseUrl) {
      dispatch(setPath(baseUrl, securityContext));
    }
  }, [baseUrl]);

  useEffect(() => {
    if (config?.filters) {
      let rF: string[] = [];
      config.filters.map((f) => {
        //@ts-ignore
        if (f.required && !isFilterInactive(f.name)) {
          rF.push(f.name);
        }
        if (f.dataTypeId == DataTypes.DateRange) {
          f.items?.forEach((fDate) => {
            //@ts-ignore
            if (fDate?.required) {
              //@ts-ignore
              rF.push(fDate.name);
            }
          });
        }
      });

      setRequiredFilters(rF);
    }
  }, [config?.filters]);

  const { ref: footerRef, inView } = useInView({
    threshold: 0.1,
    rootMargin: '0px',
  });

  const { ref: headerRef, inView: inViewHeader } = useInView({
    threshold: 0.2,
    rootMargin: '0px',
  });

  useEffect(() => {
    setIsFooterVisible(inView);
  }, [inView]);

  useEffect(() => {
    setIsHeaderVisible(inViewHeader);
  }, [inViewHeader]);

  useEffect(() => {
    if (searchModel.Filters && searchModel.Filters.length) {
      verifyRequredFilters();
    }
  }, [searchModel.Filters]);

  const isFilterInactive = (filterName: string): boolean => {
    let isInactive = false;

    config?.filters.map((f) => {
      if (f.inactivateFilterName != null) {
        if (f.name === filterName) {
          if (JSON.parse(f.inactivateFilterValues).includes(-1)) {
            let foundEmptyFilter = searchModel.Filters.find((item) => item.Name === f.inactivateFilterName) || null;
  
            if (foundEmptyFilter && (foundEmptyFilter.Value === '' || foundEmptyFilter.Value === -1)) {
              isInactive = true;
            } else if (foundEmptyFilter === null) {
              isInactive = true;
            }
          }
  
          searchModel.Filters.map((filter) => {
            if (filter.Name === f.inactivateFilterName) {
              if (JSON.parse(f.inactivateFilterValues).includes(Number(filter.Value))) {
                isInactive = true;
              }
            }
          });
        }
        }
    });

    return isInactive;
  };

  const getConfig = async () => {
    await GetConfig()
      .then((response: BackOffice) => {
        setConfig(response);
        updateConditionalConfig(response);
        if (response.displayModeId === 3) {
          //DetailedDynamic
          setIsFullWidth(false);
        } else {
          setIsFullWidth(
            response.displayModeId === 1 || response.columns.filter((column: { showInTable: boolean }) => !column.showInTable).length === 0
          );
        }
      })
      .catch((error) => {
        notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
      });
  };

  const isElementInactive = (element: any): boolean => {
    let isInactive = true;

    if (('actionId' in element)) {
      if (element['actionId'] === 'deleteFiltered' && searchModel.Filters.length === 0) {
        return true;
      }
    }

    if (!('logicalFilterName' in element)) {
      return false;
    }

    if (element.logicalFilterName !== null) {
      let filter_names = element.logicalFilterName.split('|');
      let filter_values = element.logicalFilterValue.split('|');
      let found = 0;

      for (let i = 0; i < filter_names.length; i++) {
        if (filter_values[i] === 'required') {
            searchModel.Filters.map((filter) => {
              if (filter.Name === filter_names[i]) {
                found += 1;
              }
            });
        } else {
          searchModel.Filters.map((filter) => {
            //@ts-ignore
            if (filter.Name === filter_names[i] && JSON.parse(filter_values[i]).includes(Number(filter.Value))) {
              found += 1;
            }
          });
        }
      }

      isInactive = found !== filter_names.length;
    } else {
      isInactive = false;
    }

    return isInactive;
  };

  const updateConditionalConfig = (config: BackOffice) => {
    setConditionalConfig({
      ...config,
      columns: config.columns.filter((c: any) => c.showInTable).filter((column: any) => !isElementInactive(column)),
      generalActions: config.generalActions.filter((action: any) => !isElementInactive(action))
    });
  };

  const fetchResults = async (searchModel: BackOfficeSearch, includeFilters: boolean = true) => {
    if (!verifyRequredFilters()) {
      setResponse(null);
      return;
    }

    //trim white spaces on String type filters value
    searchModel.Filters.forEach((filter: Filter) => {
      if (filter.DataTypeId === DataTypes.String) {
        let value = filter.Value as string;
        if (value.charAt(0) === ' ' || value.charAt(value.length - 1) === ' ') {
          value = value.trim();
          filter.Value = value;
        }
      }
    });

    //exclude "[]"
    searchModel.Filters = searchModel.Filters.filter((filter: Filter) => {
      if (
        (filter.DataTypeId == DataTypes.MultiSelectDropdownInt || filter.DataTypeId == DataTypes.MultiSelectDropdownString) &&
        filter.Value == '[]'
      ) {
        return false;
      }
      return true;
    });

    setDetailedElement(null);
    dispatch(toggleLoader(true));
    setResponse({
      items: [],
      metadata: {} as MetaData
    } as ResponseType);

    let model = includeFilters ? searchModel : Object.assign({}, { ...searchModel, Filters: [] });
    await GetResults({
      ...model,
      Filters: searchModel.Filters.map((filter: Filter) => {
        let stringValue;
        if (typeof filter.Value !== 'string') {
          stringValue = filter.Value + '';
        } else {
          stringValue = filter.Value;
        }
        return { ...filter, Value: stringValue };
      })
    })
      .then((response) => {
        config && updateConditionalConfig(config);
        clearSelected();
        setResponse(response);
      })
      .catch((error) => {
        if (error && error.status && error.status == 401) {
          notification.error('Sesiunea a expirat. Pagina se va reâncărca automat');
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });
    dispatch(toggleLoader(false));
  };

  useEffect(() => {
    getConfig();
  }, [basePath]);

  const onRowAction = async (values: InputValues, actionId: string, showResponseAsConfirmDialog?: boolean): Promise<boolean> => {
    let isTechnicalError = false;
    let isSessionExpired = false;
    let response = (await ActionSave(values, actionId.includes('Filtered') ? actionId + '&isFiltered=true' : actionId).catch((error) => {
      isTechnicalError = true;
      if (error.status == 401) {
        isSessionExpired = true;
        isTechnicalError = false;
      }
      return false;
    })) as { success: boolean; Success: boolean; errorMessage: string; ErrorMessage: string; [key: string]: string | boolean, redirectURL: string };
    if (response?.success === true || response?.Success === true) {
      if (response.redirectURL && response?.redirectURL !== '') {
        window.open(response?.redirectURL, '_blank');
      }

      let message = response.errorMessage ?? response.ErrorMessage ?? 'Acțiunea a fost efectuată cu succes!';
      if (showResponseAsConfirmDialog) {
        setModalTitle('Succes');
        setModalMessage(message);
        setShowModalRowActionResponse(true);
      } else {
        notification.success(message);
      }

      fetchResults({
        ...searchModel,
        PageSize: config?.pagination.defaultPageSizeValue as number,
        SortColumn: config?.sortColumn
      });

      return true;
    } else {
      if (isSessionExpired) {
        notification.error('Sesiunea a expirat. Pagina se va reâncărca automat');
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        return false;
      }

      let errorMessage = isTechnicalError
        ? 'A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.'
        : response.errorMessage ?? response.ErrorMessage ?? 'Verificati datele introduse!';

      if (showResponseAsConfirmDialog) {
        setModalTitle('Eroare');
        setModalMessage(errorMessage);
        setShowModalRowActionResponse(true);
      } else {
        notification.error(errorMessage);
      }
      return false;
    }
  };

  const updateDetailedElement = (item: DetailedElement) => {
    setDetailedElement(item);
  };

  const updateSelectedElement = (item: DetailedElement) => {
    if (config?.allowMultipleSelect) {
      setSelected((prevSelected) => {
        if (prevSelected.includes(item)) {
          return prevSelected.filter((i) => i !== item);
        } else {
          return [...prevSelected, item];
        }
      });
    }
  };

  const updateAllSelectedElements = (items: DetailedElement[]) => {
    let newArray = [];

    if (selected.length !== items.length) {
      for (let i = 0; i < items.length; i++) {
        newArray.push(items[i]);
      }
    }

    setSelected(newArray);
  };

  const clearSelected = () => {
    setSelected([]);
  };

  const isGeneralActionValidationTrue = (action: { validations?: { errorMessage: string, fieldName: string; type: string; value?: any }[] | null }) => {
    const { validations } = action; // Destructure validations from action
    if (!validations || validations.length === 0) return { isValid: true, errorMessage: '' };
  
    return validations.map(({ fieldName, type, value, errorMessage }) => {
      const field = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
      const values = selected.map(item => item[field]);
      
      let isValid: boolean;
      let error: string;
  
      switch (type) {
        case 'Same':
          const initialValue = values[0];
          isValid = values.every(itemValue => itemValue === initialValue);
          error = isValid ? '' : errorMessage;
          break;
        case 'Distinct':
          const uniqueValues = new Set(values);
          isValid = uniqueValues.size === values.length; // Check if all values are unique
          error = isValid ? '' : errorMessage;
          break;
        case 'Equal':
          isValid = values.every(itemValue => itemValue === value);
          error = isValid ? '' : errorMessage;
          break;
        default:
          isValid = false;
          error = 'Eroare';
      }
  
      return { isValid, errorMessage: error };
    });
  };   

  const fetchDetails = async (values: InputValues, actionId: string) => {
    let response: DetailActionResponse = await ActionSave(values, actionId);

    return response;
  };

  const reloadListResults = () => {
    fetchResults({
      ...searchModel,
      PageSize: config?.pagination.defaultPageSizeValue as number,
      SortColumn: config?.sortColumn
    });
  };

  const onFilterValueChange = async ({ name, value, itemTypeId }: ValueToSet) => {
    setSearchModel((prev) => {
      const filters = [...prev.Filters];
      const index = filters.findIndex((filter: Filter) => filter.Name === name);

      if (index === -1) {
        return {
          ...prev,
          Filters: [
            ...filters,
            {
              Name: name,
              Value: value,
              DataTypeId: itemTypeId
            }
          ]
        };
      } else {
        const updatedFilters = value
          ? [...filters.slice(0, index), { ...filters[index], Value: value }, ...filters.slice(index + 1)]
          : [...filters.slice(0, index), ...filters.slice(index + 1)];

        // Handle the DateRangeFrom and DateRangeTo logic
        if (itemTypeId === DataTypes.DateRangeFrom && value) {
          const nameTo = name.replace('From', 'To');
          const indexTo = filters.findIndex((x: Filter) => x.Name === nameTo && x.DataTypeId === DataTypes.DateRangeTo);

          if (indexTo !== -1 && moment(filters[indexTo].Value).isBefore(moment(value))) {
            return {
              ...prev,
              Filters: updatedFilters.filter((filter, index) => index !== indexTo)
            };
          }
        }

        return {
          ...prev,
          Filters: updatedFilters
        };
      }
    });
  };

  const verifyRequredFilters = () => {
    if (requiredFilters.length) {
      let requiredFilterNotFound = false;
      let rF: string[] = [];

      if (config?.filters) {
        config.filters.map((f) => {
          //@ts-ignore
          if (f.required && !isFilterInactive(f.name)) {
            rF.push(f.name);
          }
          if (f.dataTypeId == DataTypes.DateRange) {
            f.items?.forEach((fDate) => {
              //@ts-ignore
              if (fDate?.required) {
                //@ts-ignore
                rF.push(fDate.name);
              }
            });
          }
        });
      }

      rF.forEach((requestedFilterName) => {
        let filterValueExists = searchModel.Filters.find((f) => f.Name == requestedFilterName && f.Value !== '');
        if (!filterValueExists) {
          requiredFilterNotFound = true;
        }
      });

      if (requiredFilterNotFound) {
        setRequiredFilterNotFound(true);
        return false;
      } else {
        setRequiredFilterNotFound(false);
        return true;
      }
    }
    return true;
  };

  return (
    <BackOfficeContext.Provider
      value={{
        config,
        onRowAction,
        fetchResults,
        response,
        fetchDetails,
        isFullWidth,
        detailedElement,
        updateDetailedElement,
        setSearchModel,
        searchModel,
        reloadListResults,
        onFilterValueChange,
        requiredFilterNotFound,
        selected,
        updateSelectedElement,
        clearSelected,
        updateAllSelectedElements,
        isFilterInactive,
        conditionalConfig,
        isGeneralActionValidationTrue,
        footerRef,
        isFooterVisible,
        headerRef,
        isHeaderVisible
      }}
    >
      {children}

      <ModalConfirm
        modalTitle={modalTitle}
        showModal={showModalRowActionResponse}
        onClose={() => {
          setShowModalRowActionResponse(false);
        }}
      >
        <h5 dangerouslySetInnerHTML={{ __html: modalMessage }} />
      </ModalConfirm>
    </BackOfficeContext.Provider>
  );
};

export default BackOfficeProvider;