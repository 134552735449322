export enum DataTypes {
  String = 1,
  Int = 2,
  IntRange = 3,
  IntRangeFrom = 4,
  IntRangeTo = 5,
  Date = 6,
  DateTime = 7,
  DateRange = 8,
  DateRangeFrom = 9,
  DateRangeTo = 10,
  Dropdown = 11,
  StringList = 12,
  LocalitiesDropdown = 13,
  Url = 14,
  Bool = 15,
  HiddenString = 16,
  HiddenInt = 17,
  HiddenBool = 18,
  HTML = 20,
  ReadonlyString = 21,
  ReadonlyJson = 22,
  ReadonlyInt = 23,
  ReadonlyDate = 24,
  ReadonlyDateTime = 25,
  UrlList = 26,
  StringListEdit = 27,
  DropdownInt = 28,
  DropdownString = 29,
  Empty = 30,
  Base64File = 31,
  Base64FileList = 32,
  MultiSelectDropdownInt = 33,
  MultiSelectDropdownString = 34,
  DropdownIntSL = 35,
  GroupedFieldsList = 36,
  TableFieldsList = 37,
  FrontEndDataViewer = 38,
  YearMonthSelector = 40
}

export enum ActionTypes {
  ModalDetails = 'ModalDetails',
  ModalForm = 'ModalForm',
  Confirm = 'Confirm',
  CustomAction = 'CustomAction', //for custom Frontend implementations - used with CustomActionType or ActionId
  FrontEndImplementation = 'FrontEndImplementation' //for custom Frontend implementations - used with CustomActionType or ActionId
}

export enum CustomActionTypes {
  Timeline = 'Timeline'
}

export enum ActionIds {
  GRAFICE_DATE_MASURA = 'GRAFICE_DATE_MASURA',
  RSS_CREATE = 'RSS_CREATE'
}

export type DetailActionResponse = {
  [key: string]: string | any;
};

export enum ModalSize {
  Small = 1,
  Medium = 2,
  Large = 3,
  FullScreen = 4
}

export let IntegerTypes = [DataTypes.Int, DataTypes.HiddenInt, DataTypes.ReadonlyInt, DataTypes.DropdownInt];

export class BackOfficeSearch {
  PageIndex: number = 1;
  PageSize: number | null = null;
  SortColumn?: string | null = null;
  SortAscending: boolean = false;
  GeneralSearchText: string | null = '';
  Filters: Filter[] = [];
}
export type Filter = {
  Name: string;
  Value: string | number | string[];
  DataTypeId: number;
};

export type OptionsForField = {
  field: string;
  keyFieldName: string;
  child: string | any;
  childUrl: string | any;
  childUrlQuerryParameterName: string | any;
  parent: string | any;
  parentValue: string | number | any;
  options: Option[];
};
export type Option = {
  [key: string | number]: any;
};

export type ValueToSet = {
  name: string;
  value: string | number | string[] | any;
  itemTypeId: number;
};

export interface BackOffice {
  sectionName: string;
  sortColumn: string;
  sortAscending: boolean;
  displayModeId: number;
  displayModeName: string;
  dynamicRowDetailsColumnNames: string[];
  displayGroups: DisplayGroup[];
  exportButton: ExportButton;
  pagination: Pagination;
  generalActions: ActionType[];
  showGeneralSearchInput: boolean;
  filters: Field[];
  advancedFilters: Field[];
  columns: Column[];
  rowActions: ActionType[];
  identityColumnDataTypeId: number;
  identityColumnDataTypeName: string;
  identityColumnName: string;
  loggedInUserRoles: string[];
  errors: string;
  allowMultipleSelect: boolean;
  tableMinWidth: string;
  showFooter: boolean;
}

export interface DisplayGroup {
  id: number;
  displayName: string;
  numberOfColumnsPerRow: number;
  visibilityControlFieldName?: string;
}

export interface ExportButton {
  active: boolean;
  buttonDisplayName: string;
  fileName: string;
  rowsCountMaxLimit: number;
}

export interface Pagination {
  active: boolean;
  defaultPageSizeValue: number;
  pageSizeOptionValues: number[];
}

export interface ActionType {
  actionType: number;
  actionTypeName: string;
  actionId: string;
  displayName: string;
  displayMessage: string;
  logicalConditionFieldName: string;
  iconName?: string;
  buttonTooltip?: string;
  formGroups?: FormGroup[];
  fields?: Field[];
  preloadDataAction?: PreloadDataAction;
  redirectQueryParametes: string;
  redirectUrl: string;
  highlightTypeFieldName: string;
  showTextWhenLogicalConditionIsFalse: string;
  modalSize: ModalSize;
  showConfirmPopup: boolean;
  confirmMessage: string;
  showApiResponseAsConfirmDialog: boolean;
  customActionTypeName: string;
  downloadFileName: string;
  allowMultipleSelect: boolean;
  validations?: any;
}

export interface FormGroup {
  name: string;
  displayName: string;
  numberOfColumnsPerRow: number;
  fields: Field[];
}

export interface Field {
  name: string;
  isRequired: boolean;
  dataTypeId: number;
  value: any;
  dataTypeName: string;
  displayName: string;
  colspan: number;
  hint: string | null;
  items?: any[];
  itemsConfiguration?: ItemsConfiguration;
  isAdvancedFilter?: boolean;
  inactivateFilterName?: string;
  inactivateFilterValues: string;
  required?: boolean;
  maxValue?: string | number | any;
  minValue?: string | number | any;
  initializeValueFromQueryString?: boolean;
  allowedFileExtensions?: string[] | any;
  allowedMaxTotalFileSizeMB?: number;
  visibilityConditionedBy?: {
    filterId: string;
    filterValue: string;
  };
  color: 'red';
  fontStyle: 'bold';
  showRowIndex: boolean;
  hideIfEmptyValue: boolean;
  rows: number;
  messageForEmptyValue: string;
}

export interface ItemsConfiguration {
  staticData: boolean;
  showAsRadioButtons: boolean;
  keyFieldName: string;
  valueFieldName: string;
  itemsDefinition: ItemsDefinition | null;
  extraPropertiesToSendInActionModel: string[] | null;
  cascadeChild: Field | null;
  detailsFieldName: string;
}

export interface ItemsDefinition {
  internalApi: boolean;
  url: string;
  queryStringSourceParameterName: string | null;
}

export interface Column {
  name: string;
  displayName: string;
  dataTypeName: string;
  dataTypeId: number;
  displayGroupId: number;
  sorting: boolean;
  showInTable: boolean;
  detailsColspan: number;
  columnSize: string | null;
  headerCustomDisplayName: string | null;
  hideIfEmptyValue: boolean;
  messageForEmptyValue: string;
  value: string | number | any;
}

export interface PreloadDataAction {
  actionType: number;
  actionTypeName: string;
  actionId: string;
  displayName: string;
  displayMessage: string;
  fields: any;
  preloadDataAction: any;
}
