import React, { useEffect, useRef, useState } from 'react';

import { BackOfficeContext, BackOfficeContextType, ResponseType } from '@contexts/backoffice-context';
import { faCircleArrowRight, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColumnValue from './ColumnValue';
import RowActions from './RowActions';
import { BackOfficeSearch } from '@type/backOfficeModule';
import Tippy from '@tippyjs/react';
import { Row, Col } from 'react-bootstrap';
import { Dropdown } from '@components/common/Dropdown';
import { ToString } from '@lib/Utils';
import { useSelector } from 'react-redux';
import { IState } from '@type/store';

export type ValueToSet = {
  name: string;
  value: string;
  itemTypeId: number;
};

type ResponseListingProps = {
  response?: ResponseType | null;
  onSort: (column: string) => void;
  searchModel: BackOfficeSearch;
  setSearchModel: any;
  renderPages: any;
};

const DISPLAY_NO_WRAP_BASE_URLS = [
  'compensation/BackOfficeImport',
  'compensation/BackOfficeCompensationEE',
  'compensation/BackOfficeCompensationDirectClients',
  'compensation/BackOfficeCompensationDetails',
  'compensation/BackOfficeCompensationDirectClientsDetails',
  'compensation/BackOfficeCorrespondenceEmail',
  'compensation/BackOfficeYears'
];

export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;

const ResponseListing = ({ response, onSort, searchModel, setSearchModel, renderPages }: ResponseListingProps) => {
  const {
    config,
    isFullWidth,
    updateDetailedElement,
    detailedElement,
    selected,
    updateSelectedElement,
    updateAllSelectedElements,
    conditionalConfig,
    isFooterVisible,
    clearSelected,
    isHeaderVisible,
    headerRef
  } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const [existsRowActions, setExistsRowActions] = useState(false);
  const [existsLeftRowActions, setExistsLeftRowActions] = useState(false);
  const [tableMinWidth, setTableMinWidth] = useState('');
  const [tableContainerWidth, setTableContainerWidth] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const basePath: any = useSelector<IState>((state) => state.backOffice.basePath);

  useEffect(() => {
    if (config?.rowActions?.length) {
      setExistsRowActions(true);
    }

    if (config?.rowActions?.length) {
      let exists = false;

      config.rowActions.map((item: any) => {
        if (item.showRowActionButtonOnLeft === true) {
          exists = true;
        }
      });

      setExistsLeftRowActions(exists);
    }

    if (config?.tableMinWidth) {
      setTableMinWidth(config?.tableMinWidth);
    }
  }, [config]);

  useEffect(() => {
    if (response?.items && response.items.length > 0) {
      updateDetailedElement(response.items[0]);
    }
  }, [response]);

  const renderFooterColumn = (columnName: string) => {
    let found = null;

    response?.metadata?.footerTotals.map((item: any, index: number) => {
      if (item.columnName === columnName) {
        found = item.value;
      }
    });

    return found;
  };

  useEffect(() => {
    const element = document.querySelector('.bo-table-container') as HTMLElement;
    const tableElement = document.querySelector('.bo-response-listing') as HTMLElement;

    const updateWidth = () => {
      if (element && tableElement) {
        setTableContainerWidth(element.offsetWidth);
        setScrollWidth(tableElement.offsetWidth);
      }
    };

    const observer = new MutationObserver(() => updateWidth());

    if (element) {
      observer.observe(element, { attributes: true, childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, []);

  const dynamicControlRef = useRef<HTMLDivElement | null>(null);
  const dynamicHeaderControlRef = useRef<HTMLTableSectionElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);

  const handleScroll = () => {
    if (dynamicControlRef.current && tableRef.current) {
      tableRef.current.scrollLeft = dynamicControlRef.current.scrollLeft;
    }

    if (dynamicHeaderControlRef.current && tableRef.current) {
      dynamicHeaderControlRef.current.scrollLeft = tableRef.current.scrollLeft;
    }

    if (tableRef.current && dynamicControlRef.current) {
      tableRef.current.scrollLeft = dynamicControlRef.current.scrollLeft;
    }
  };

  const handleHeaderScroll = () => {
    if (tableRef.current && dynamicHeaderControlRef.current) {
      tableRef.current.scrollLeft = dynamicHeaderControlRef.current.scrollLeft;
    }

    if (dynamicControlRef.current && dynamicHeaderControlRef.current) {
      dynamicControlRef.current.scrollLeft = dynamicHeaderControlRef.current.scrollLeft;
    }

    if (tableRef.current && dynamicControlRef.current) {
      tableRef.current.scrollLeft = dynamicControlRef.current.scrollLeft;
    }
  };

  const getHeadCellWidth = (index: number) => {
    const spanElement = document.querySelector(`.pointer.fw-bold.span-${index}`) as HTMLSpanElement | null;

    if (spanElement) {
      return `${spanElement.offsetWidth}px`;
    } else {
      return 'auto';
    }
  };

  const renderDynamicTableHead = () => {
    if (config && response) {
      return (
        <div
          className="bo-dynamic-header"
          ref={dynamicHeaderControlRef}
          onScroll={handleHeaderScroll}
          style={{ width: `${tableContainerWidth}px` }}
        >
          <thead>
            <tr>
              {response && response.items && config && config.allowMultipleSelect && (
                <th className="fw-bold" style={{ fontSize: 14 }}>
                  <div
                    style={{ minWidth: '50px' }}
                    className="d-flex justify-content-center align-items-center"
                    onClick={() => updateAllSelectedElements(response.items)}
                  >
                    <input
                      className="pointer"
                      type="checkbox"
                      style={{ width: '16px' }}
                      onChange={() => null}
                      checked={selected.length === response.items.length}
                    />
                  </div>
                </th>
              )}

              {existsLeftRowActions && (
                <th style={{ fontSize: 14, minWidth: '64px' }}>
                  <span className={'pointer fw-bold'} style={{ display: 'block', width: getHeadCellWidth(-1) }}>
                    Acțiuni
                  </span>
                </th>
              )}

              {conditionalConfig &&
                conditionalConfig.columns.map(
                  (
                    column: {
                      displayName: string;
                      name: string;
                      sorting: boolean;
                      dataTypeId: number;
                      columnSize: string | any;
                      headerCustomDisplayName: string | any;
                    },
                    index: number
                  ) => {
                    return column.sorting === true ? (
                      <th key={index}>
                        <span
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            onSort(column.name);
                          }}
                          className={'pointer fw-bold'}
                          style={{
                            fontSize: 14,
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            width: getHeadCellWidth(index)
                          }}
                        >
                          <span>{column.headerCustomDisplayName ? column.headerCustomDisplayName : column.displayName}</span>
                          {searchModel.SortColumn == column.name ? (
                            searchModel.SortAscending ? (
                              <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                            ) : (
                              <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} size="sm" />
                          )}
                        </span>
                      </th>
                    ) : (
                      <th key={index}>
                        <span className="fw-bold" style={{ fontSize: 14 }}>
                          {column.headerCustomDisplayName ? column.headerCustomDisplayName : column.displayName}
                        </span>
                      </th>
                    );
                  }
                )}
              <th className="fw-bold" style={{ fontSize: 14 }}>
                <span className={`pointer fw-bold`} style={{ minHeight: '1px', display: 'block', width: getHeadCellWidth(-2) }}>
                  {isFullWidth && !existsRowActions ? 'Acțiuni' : ''}
                </span>
              </th>
            </tr>
          </thead>
          <div className="dynamic-header-overflow-control" ref={dynamicHeaderControlRef} onScroll={handleHeaderScroll}></div>
        </div>
      );
    }
  };

  const renderDynamicPagination = () => {
    if (config && response) {
      return (
        <div className="bo-dynamic-footer" style={{ width: `${tableContainerWidth}px` }}>
          <div
            className="dynamic-footer-overflow-control"
            style={{ width: `${tableContainerWidth}px` }}
            ref={dynamicControlRef}
            onScroll={handleScroll}
          >
            <div className="overflow-control" style={{ width: `${scrollWidth}px` }} ref={dynamicControlRef} onScroll={handleScroll} />
          </div>
          <Row className="mt-4">
            <Col md={2} sm={12}>
              <Dropdown
                options={config.pagination.pageSizeOptionValues.map((item: number) => ({
                  id: ToString(item),
                  name: ToString(item)
                }))}
                label={'Elemente pagina'}
                value={ToString(searchModel.PageSize) ?? ''}
                onChange={(value) => {
                  setSearchModel({
                    ...searchModel,
                    PageSize: parseInt(value),
                    PageIndex: 1
                  });
                }}
              />
            </Col>
            {response.metadata && response.metadata.pageSize <= response.metadata.totalItemsCount && (
              <Col md={9} sm={12}>
                <nav>
                  <ul className="pagination">
                    <span
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        if (searchModel.PageIndex > 1) setSearchModel({ ...searchModel, PageIndex: 1 });
                        clearSelected();
                      }}
                      className={response.metadata.pageSize === 1 ? 'nav-icon-disabled' : 'nav-icon'}
                    >
                      «
                    </span>

                    <span
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        if (searchModel.PageIndex > 1) setSearchModel({ ...searchModel, PageIndex: searchModel.PageIndex - 1 });
                        clearSelected();
                      }}
                      className={response.metadata.pageSize === 1 ? 'nav-icon-disabled' : 'nav-icon'}
                    >
                      &lt;
                    </span>
                    {renderPages(response.metadata)}
                    <span
                      className="nav-icon"
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        if (response.metadata && response.metadata.totalItemsCount && response.metadata.pageSize) {
                          let totalPages: number = Math.ceil(response.metadata.totalItemsCount / response.metadata.pageSize);
                          if (response.metadata.pageIndex < totalPages)
                            setSearchModel({ ...searchModel, PageIndex: response.metadata.pageIndex + 1 });
                          clearSelected();
                        }
                      }}
                    >
                      &gt;
                    </span>

                    <span
                      className="nav-icon"
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        if (response.metadata && response.metadata.totalItemsCount && response.metadata.pageSize) {
                          let totalPages: number = Math.ceil(response.metadata.totalItemsCount / response.metadata.pageSize);
                          if (response.metadata.pageIndex < totalPages) setSearchModel({ ...searchModel, PageIndex: totalPages });
                          clearSelected();
                        }
                      }}
                    >
                      »
                    </span>
                  </ul>
                </nav>
              </Col>
            )}
          </Row>
        </div>
      );
    }
  };

  const shouldRenderDynamicScrolls = () => {
    if (window.location.href.includes('compensatii') || window.location.href.includes('detalii-plati')) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {config && (
        <div className="bo-table-container" ref={tableRef} onScroll={handleScroll}>
          {!isFooterVisible && shouldRenderDynamicScrolls() && renderDynamicPagination()}
          <table style={{ minWidth: tableMinWidth ? tableMinWidth + 'px' : '' }} className="bo-response-listing">
            {!isHeaderVisible && shouldRenderDynamicScrolls() && renderDynamicTableHead()}
            <thead ref={headerRef}>
              <tr>
                {response && response.items && config && config.allowMultipleSelect && (
                  <th className="fw-bold" style={{ fontSize: 14 }}>
                    <div
                      style={{ minWidth: '50px' }}
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => updateAllSelectedElements(response.items)}
                    >
                      <input
                        className="pointer"
                        type="checkbox"
                        style={{ width: '16px' }}
                        onChange={() => null}
                        checked={selected.length === response.items.length}
                      />
                    </div>
                  </th>
                )}

                {existsLeftRowActions && (
                  <th className="fw-bold" style={{ fontSize: 14 }}>
                    <span className={`w-100 d-block pointer fw-bold span--1`}>Acțiuni</span>
                  </th>
                )}

                {conditionalConfig &&
                  conditionalConfig.columns.map(
                    (
                      column: {
                        displayName: string;
                        name: string;
                        sorting: boolean;
                        dataTypeId: number;
                        columnSize: string | any;
                        headerCustomDisplayName: string | any;
                      },
                      index: number
                    ) => {
                      return column.sorting === true ? (
                        <th key={index}>
                          <span
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              onSort(column.name);
                            }}
                            className={`pointer fw-bold span-${index}`}
                            style={{
                              fontSize: 14,
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px'
                            }}
                          >
                            <span>{column.headerCustomDisplayName ? column.headerCustomDisplayName : column.displayName}</span>
                            {searchModel.SortColumn == column.name ? (
                              searchModel.SortAscending ? (
                                <FontAwesomeIcon icon={faSortUp} size="sm" color="#bfbfbf" />
                              ) : (
                                <FontAwesomeIcon icon={faSortDown} size="sm" color="#bfbfbf" />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} size="sm" />
                            )}
                          </span>
                        </th>
                      ) : (
                        <th key={index}>
                          <span className="fw-bold" style={{ fontSize: 14 }}>
                            {column.headerCustomDisplayName ? column.headerCustomDisplayName : column.displayName}
                          </span>
                        </th>
                      );
                    }
                  )}
                <th className="fw-bold p-0" style={{ fontSize: 14 }}>
                  <span className={`pointer fw-bold span--2 w-100 d-block`}>{isFullWidth && !existsRowActions ? 'Acțiuni' : ''}</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {response?.items.map((item: { [key: string]: string | any }, index: number) => (
                <tr
                  key={index}
                  onClick={() => {
                    updateDetailedElement(item);
                  }}
                  className={`${
                    detailedElement &&
                    item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)] ===
                      detailedElement[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)]
                      ? `selected ${item.hasErrors ? 'has-errors' : ''}`
                      : item.hasErrors
                      ? 'has-errors'
                      : ''
                  }`}
                >
                  {config.allowMultipleSelect && (
                    <td onClick={() => updateSelectedElement(item)}>
                      <div className="d-flex justify-content-center align-items-center">
                        <input
                          className="pointer"
                          type="checkbox"
                          style={{ width: '16px' }}
                          onChange={() => null}
                          checked={selected.includes(item)}
                        />
                      </div>
                    </td>
                  )}

                  {existsLeftRowActions && (
                    <td>
                      <RowActions item={item} filter={true} filterName={'displayButtonInTable'} rowAlign={true} />
                    </td>
                  )}

                  {conditionalConfig &&
                    conditionalConfig.columns.map(
                      (
                        column: { displayName: string; name: string; dataTypeId: number; showInTable: boolean; columnSize: string | any },
                        columnNumber: number
                      ) => {
                        const colSize = column.columnSize ? column.columnSize : 'Medium';
                        const colSizeUnits = colSize === 'Large' ? 4 : colSize === 'Small' ? 1 : 2;
                        const colSizeInPixels = 50 * colSizeUnits;

                        return (
                          <>
                            {basePath && DISPLAY_NO_WRAP_BASE_URLS.includes(basePath) ? (
                              <td
                                key={columnNumber}
                                style={
                                  column.name !== 'Indicator'
                                    ? { minWidth: colSizeInPixels + 'px', fontSize: 14, maxHeight: '21px', whiteSpace: 'nowrap' }
                                    : { width: '150px' }
                                }
                              >
                                {column.name === 'Indicator' ? (
                                  <Tippy
                                    content={
                                      <div
                                        style={{
                                          background: 'black',
                                          textAlign: 'center',
                                          color: 'white',
                                          borderRadius: '4px',
                                          padding: '8px'
                                        }}
                                      >
                                        {item[column.name?.charAt(0).toLowerCase() + column?.name?.slice(1)]}
                                      </div>
                                    }
                                    duration={0}
                                    placement="top"
                                  >
                                    <span
                                      style={{
                                        width: '150px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        display: 'block'
                                      }}
                                    >
                                      {item[column.name?.charAt(0).toLowerCase() + column?.name?.slice(1)]}
                                    </span>
                                  </Tippy>
                                ) : (
                                  <ColumnValue column={column} item={item} />
                                )}
                              </td>
                            ) : (
                              <td key={columnNumber} style={{ minWidth: colSizeInPixels + 'px', fontSize: 14, maxHeight: '21px' }}>
                                <ColumnValue column={column} item={item} />
                              </td>
                            )}
                          </>
                        );
                      }
                    )}

                  {isFullWidth && !existsLeftRowActions ? (
                    <td style={{ paddingLeft: '5px' }}>
                      <RowActions item={item} />
                    </td>
                  ) : (
                    <td className="text-end" style={{ maxWidth: '60px', minWidth: '60px' }}>
                      <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        size={'1x'}
                        className="expand-collapse pt-1 pe-2 pointer"
                        color="#bebebe"
                      />
                    </td>
                  )}
                </tr>
              ))}

              {!response?.items.length && (
                <div className="p-2 fw-bold red">
                  <p>Nu există date pentru filtrele aplicate!</p>
                </div>
              )}

              {config.showFooter && response?.metadata?.footerTotals && (
                <tr className="footer-row">
                  {existsLeftRowActions && <td className="footer-cell"></td>}
                  {config.allowMultipleSelect && <td className="footer-cell"></td>}
                  {conditionalConfig &&
                    conditionalConfig.columns.map(
                      (
                        column: {
                          displayName: string;
                          name: string;
                          sorting: boolean;
                          dataTypeId: number;
                          columnSize: string | any;
                          headerCustomDisplayName: string | any;
                        },
                        index: number
                      ) => (
                        <td key={index} className="footer-cell">
                          <span className="fw-bold" style={{ fontSize: 14 }}>
                            {renderFooterColumn(column.name)}
                          </span>
                        </td>
                      )
                    )}
                  {isFullWidth && <td className="footer-cell"></td>}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ResponseListing;
