import React from 'react';

import BackofficeChart from '@components/delgaz/Requests/BackofficeChart';
import ModalComponent from '@components/dev/Components/Modal/Modal';
import { BackOfficeContext, BackOfficeContextType } from '@contexts/backoffice-context';
import { ActionType, ModalSize } from '@type/backOfficeModule';

type CustomActionOrdersChartProps = {
  action: ActionType;
  isEditing: boolean;
  setIsEditing: (val: boolean) => void;
  item: any;
};

const CustomActionOrdersChart = (props: CustomActionOrdersChartProps) => {
  const { action, isEditing, item } = props;
  const { setIsEditing } = props;

  const { config } = React.useContext(BackOfficeContext) as BackOfficeContextType;

  return (
    <ModalComponent
      title={action.displayName}
      show={isEditing}
      className="backoffice-modal"
      setShow={(state: boolean) => {
        setIsEditing(!isEditing);
      }}
      fullScreen={action.modalSize && action.modalSize == ModalSize.FullScreen ? true : 'md-down'}
      size={'lg'}
      backdropStatic
    >
      <div className="mt-2">
        {config && (
          <BackofficeChart
            type={item.reportTypeName}
            id={item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)]}
          />
        )}
      </div>
    </ModalComponent>
  );
};

export default CustomActionOrdersChart;
