import React, { useState } from 'react';

import { toggleLoader } from '@actions/LoaderActions';
import ModalComponent from '@components/dev/Components/Modal/Modal';
import { BackOfficeContext, BackOfficeContextType } from '@contexts/backoffice-context';
import { ActionType } from '@type/backOfficeModule';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import useBoHelpers from '../Hooks/useBoHelpers';

type ActionConfirmProps = {
  action: ActionType;
  isEditing: boolean;
  setIsEditing: (val: boolean) => void;
  item?: any; //only for row actions
  isRowAction: boolean;
};

const ActionConfirm = (props: ActionConfirmProps) => {
  const { onRowAction, clearSelected } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const { config } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState<boolean>(false);

  const { action, isEditing, item, isRowAction } = props;
  const { setIsEditing } = props;
  const { getModalSize, getMessageToDisplayWithPlaceholder, checkMultipleSelectValues, displayMessageWithPlaceholder } = useBoHelpers();

  const dispatch = useDispatch();

  return (
    <ModalComponent
      title={action.displayName}
      show={isEditing}
      setShow={(state: boolean) => {
        setIsEditing(!isEditing);
      }}
      size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
      footerAction={
        <Button
          className="btn btn-primary"
          onClick={async (e) => {
            setConfirmButtonDisabled(true);
            dispatch(toggleLoader(true));
            let success: boolean;
            if (onRowAction) {
              if (isRowAction) {
                //ROW ACTION
                success = await onRowAction(
                  {
                    [config!.identityColumnName]:
                      item[config!.identityColumnName.charAt(0).toLowerCase() + config!.identityColumnName.slice(1)]
                  },
                  action.actionId,
                  action.showApiResponseAsConfirmDialog
                );
                setConfirmButtonDisabled(false);
                if (success) {
                  setIsEditing(false);
                }
              } else {
                //GENERAL ACTION
                success = await onRowAction(checkMultipleSelectValues(action, {}), action.actionId, action.showApiResponseAsConfirmDialog);
                if (success) {
                  setIsEditing(false);
                  setConfirmButtonDisabled(false);
                  clearSelected();
                }
              }
              dispatch(toggleLoader(false));
            }
          }}
          variant="primary"
          disabled={confirmButtonDisabled}
        >
          {action.displayName}
        </Button>
      }
    >
      {
        <p>
          {isRowAction && item
            ? getMessageToDisplayWithPlaceholder(item, action.displayMessage)
            : displayMessageWithPlaceholder(action.displayMessage)}
        </p>
      }
    </ModalComponent>
  );
};

export default ActionConfirm;
