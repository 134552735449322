import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import { useEffect, useState } from 'react';
import { ValueToSet } from '@type/backOfficeModule';

type Base64FileListProps = {
  name: string;
  value?: Value | any;
  label: string;
  itemTypeId: number;
  onChange?: (e: ValueToSet) => void;
  viewFilesValues?: any;
  allowedFileExtensions: string[] | any;
  allowedMaxTotalFileSizeMB: number | any;
  mbZero?: boolean;
};

interface Value {
  containerName: null;
  blobName: string;
  documentUrl: string;
  originalFileName: string;
  fileCreatedDate: string;
}

const Base64File = ({
  name,
  label,
  onChange,
  itemTypeId,
  value,
  viewFilesValues,
  allowedFileExtensions,
  allowedMaxTotalFileSizeMB,
  mbZero
}: Base64FileListProps) => {
  const [fileList, setFileList] = useState<File[]>([] as File[]);
  const [deletedFileToSendBack, setDeletedFileToSendBack] = useState<File | null>(null);

  useEffect(() => {
    //load initial value from props
    if (value && value.documentUrl) {
      handleFileListChange([
        {
          name: value.originalFileName,
          fileUrl: value.documentUrl,
          blobName: value.blobName
        }
      ]);
    }
  }, [value]);

  const handleFileListChange = (list: File[]) => {
    setFileList(list);

    onChange &&
      onChange({
        name: name,
        value: list.map((li) => {
          const base64 = li.fileUrl ? null : li.base64.slice(li.base64.lastIndexOf('base64,') + 7);
          let v: any = {
            NumeDocument: li.name,
            ContinutDocument: base64
          };

          if (deletedFileToSendBack) {
            v.Delete = {
              ContainerName: deletedFileToSendBack.containerName,
              BlobName: deletedFileToSendBack.blobName
            };
          }
          return v;
        })[0],
        itemTypeId
      } as ValueToSet);
  };

  const handleFileRemove = (fileToDelete: File) => {
    let updatedfileList;
    if (fileToDelete.fileUrl) {
      setDeletedFileToSendBack(fileToDelete);
    }
    updatedfileList = [...fileList.filter((f) => f.name != fileToDelete.name)];
    handleFileListChange(updatedfileList);
  };

  return (
    <div className={mbZero ? '' : 'mb-3'}>
      <label style={{ position: 'relative', left: '5px' }}>
        <div>{label}</div>
      </label>
      <UploadFile
        acceptedExtensions={allowedFileExtensions ? allowedFileExtensions : null}
        uploadedFilesLimit={1}
        index={0}
        fileList={fileList}
        onSetFileList={handleFileListChange}
        onFileRemove={handleFileRemove}
        totalSizeLimit={allowedMaxTotalFileSizeMB}
      />
    </div>
  );
};

export default Base64File;
