import React, { useMemo } from 'react';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { ValueToSet } from '@type/backOfficeModule';

type StringListProps = {
  options: string[];
  name: string;
  value?: string | number;
  label: string;
  itemTypeId: number;
  onChange?: (e: ValueToSet) => void;
};

const StringList = ({ options, name, label, onChange, itemTypeId, value }: StringListProps) => {
  const optionsbuilder = (options: string[]): DropdownOptionType[] => {
    return options.map(
      (item: string) =>
        ({
          id: item,
          name: item,
          disabled: false
        } as DropdownOptionType)
    );
  };

  const dropdownOptions = useMemo<DropdownOptionType[]>(() => optionsbuilder(options), [options]);

  return (
    <Dropdown
      options={dropdownOptions}
      name={name}
      value={value}
      label={label}
      defaultPlaceholder={`Selectează ${label}`}
      onChange={(value) => onChange && onChange({ name, value, itemTypeId } as ValueToSet)}
    />
  );
};

export default StringList;
