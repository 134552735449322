import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import IstoricIndecsiTransmisi from './IstoricIndecsiTransmisi';

const IstoricIndecsiComp = () => {
  return (
    <div className="m-3">
      <Tabs defaultActiveKey={1} className="my-account-tabs" mountOnEnter={true} unmountOnExit={true}>
        <Tab eventKey={1} title="Energie Electrică" mountOnEnter={true} unmountOnExit={true}>
          <IstoricIndecsiTransmisi utility={1} />
        </Tab>
        <Tab eventKey={2} title="Gaze Naturale" mountOnEnter={true} unmountOnExit={true}>
          <IstoricIndecsiTransmisi utility={2} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default IstoricIndecsiComp;