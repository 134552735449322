import React, { useState } from 'react';

import GetActionIcon from '@constants/BOActionIcons';
import { BackOfficeContext, BackOfficeContextType } from '@contexts/backoffice-context';
import { ToString } from '@lib/Utils';
import Tippy from '@tippyjs/react';
import { ActionIds, ActionType, ActionTypes, CustomActionTypes, DataTypes, DetailActionResponse } from '@type/backOfficeModule';
import { Button } from 'react-bootstrap';
import ActionConfirm from './Actions/ActionConfirm';
import ActionModalDetails from './Actions/ActionModalDetails';
import ActionModalForm from './Actions/ActionModalForm';
import CustomActionOrdersChart from './CustomActions/CustomActionOrdersChart';
import CustomActionTimeline from './CustomActions/CustomActionTimeline';
import useBoHelpers from './Hooks/useBoHelpers';

const HIGHLIGHT_TYPE_DEFAULT = '0';
const HIGHLIGHT_TYPE_HIGH = '1';
const HIGHLIGHT_TYPE_LOW = '2';

const RowActions = ({ item, filter, filterName, rowAlign }: { [key: string]: string | any }) => {
  const { config, fetchDetails } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [action, setAction] = useState<ActionType | null>(null);
  const [detailActionValues, setDetailActionValues] = useState<DetailActionResponse | DetailActionResponse[]>({} as DetailActionResponse);
  const [viewFilesValues, setViewFilesValues] = useState<any>([]);

  const { downloadFileAction, getUrlParamsStringForRequestedQueryParams } = useBoHelpers();

  const applyRowActionFilter = (rowActions: any[]) => {
    if (!filterName) {
      return rowActions;
    } else {
      return rowActions.filter((item: any) => (item[filterName] ? item[filterName] === filter : rowActions));
    }
  };

  return (
    <>
      {config && config.rowActions && (
        <div className={`${rowAlign ? 'd-flex flex-row' : 'd-flex flex-wrap'}`}>
          {applyRowActionFilter(config.rowActions).map((action: ActionType, key: number) => {
            if (
              action.logicalConditionFieldName === '' ||
              (action.logicalConditionFieldName &&
                item[action.logicalConditionFieldName.charAt(0).toLowerCase() + action.logicalConditionFieldName.slice(1)])
            ) {
              let highlightType = HIGHLIGHT_TYPE_DEFAULT;

              if (action.highlightTypeFieldName) {
                highlightType = item[action.highlightTypeFieldName.charAt(0).toLowerCase() + action.highlightTypeFieldName.slice(1)];
              }

              const r_action = (
                <Button
                  key={key}
                  className={`m-1 ${
                    highlightType == HIGHLIGHT_TYPE_HIGH ? 'btn-high' : highlightType == HIGHLIGHT_TYPE_LOW ? 'btn-low' : ''
                  }`}
                  onClick={(e) => {
                    setAction(action);
                    setIsEditing(true);

                    if (action.actionTypeName === ActionTypes.ModalDetails && action.preloadDataAction) {
                      let value = item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)];
                      setDetailActionValues({});
                      let response =
                        fetchDetails &&
                        fetchDetails(
                          {
                            [config.identityColumnName]:
                              config.identityColumnDataTypeId === DataTypes.Int ? parseInt(value) : ToString(value)
                          },
                          action?.preloadDataAction?.actionId
                        );

                      response.then((responseData: DetailActionResponse) => {
                        setDetailActionValues(responseData);
                      });
                    } else if (
                      action.actionTypeName === ActionTypes.ModalForm &&
                      action.preloadDataAction &&
                      action.preloadDataAction.actionId === 'view_files'
                    ) {
                      let value = item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)];
                      setDetailActionValues({});
                      let response =
                        fetchDetails &&
                        fetchDetails(
                          {
                            [config.identityColumnName]:
                              config.identityColumnDataTypeId === DataTypes.Int ? parseInt(value) : ToString(value)
                          },
                          action?.preloadDataAction?.actionId
                        );

                      response.then((responseData: any) => {
                        setViewFilesValues(responseData);
                      });
                    } else if (
                      action.actionTypeName === 'DownloadInternalAction' ||
                      action.actionTypeName === 'DownloadInternal' ||
                      action.actionTypeName === 'DownloadExternal'
                    ) {
                      downloadFileAction(action, item);
                    } else if (action.actionTypeName === 'Redirect') {
                      let urlParams = getUrlParamsStringForRequestedQueryParams(action.redirectQueryParametes, item);
                      let redirect = action.redirectUrl + urlParams;
                      let a = document.createElement('a');
                      a.target = '_blank';
                      a.href = redirect;
                      a.click();
                    }
                  }}
                >
                  <span style={{ width: '36px', height: '36px' }}>
                    {action.iconName ? GetActionIcon(action.iconName) : action.displayName}
                  </span>
                </Button>
              );

              if (action.iconName) {
                return (
                  <Tippy
                    content={
                      <span style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: '8px', borderRadius: '8px', color: '#fff' }}>
                        {action.buttonTooltip}
                      </span>
                    }
                    placement="top"
                    duration={0}
                  >
                    {r_action}
                  </Tippy>
                );
              } else {
                return r_action;
              }
            } else if (action.showTextWhenLogicalConditionIsFalse) {
              let btnMessage = action.showTextWhenLogicalConditionIsFalse;
              return (
                <button type="button" className="btn btn-secondary ms-2" disabled={true}>
                  {btnMessage}
                </button>
              );
            } else {
              return;
            }
          })}
        </div>
      )}

      {action?.actionTypeName === ActionTypes.ModalForm && (
        <ActionModalForm
          action={action}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          viewFilesValues={viewFilesValues}
          item={item}
          isRowAction={true}
        />
      )}

      {action?.actionTypeName === ActionTypes.Confirm && (
        <ActionConfirm action={action} isEditing={isEditing} setIsEditing={setIsEditing} item={item} isRowAction={true} />
      )}

      {action?.actionTypeName === ActionTypes.ModalDetails && (
        <ActionModalDetails
          action={action}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          detailActionValues={detailActionValues}
          item={item}
        />
      )}

      {action?.actionTypeName === ActionTypes.CustomAction && action.customActionTypeName == CustomActionTypes.Timeline && (
        <CustomActionTimeline action={action} isEditing={isEditing} setIsEditing={setIsEditing} item={item} />
      )}

      {action?.actionTypeName == ActionTypes.FrontEndImplementation && action?.actionId == ActionIds.GRAFICE_DATE_MASURA && (
        <CustomActionOrdersChart action={action} isEditing={isEditing} setIsEditing={setIsEditing} item={item} />
      )}
    </>
  );
};

export default React.memo(RowActions);
