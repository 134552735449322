import React, { Fragment, useState, useEffect, useRef } from 'react';
import { faBell as OutlineIcon } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope as EnvelopeIcon } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { IState } from '@type/store';
import { notificationsSummary, markNotificationAsRead } from '@api/userNotifications';
import moment from 'moment';
import { toggleLoader } from '@actions/LoaderActions';
import { useDispatch } from 'react-redux';
import { updateNotificationSummary, updateNotificationCount, externalNotificationUpdate } from '@actions/NotificationActions';

const envelope = EnvelopeIcon as IconProp;
const outlineBell = OutlineIcon as IconProp;

const UserNotifications = (props: { isLoggedin: boolean }) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const popupRef = useRef(null);
  const buttonRef = useRef(null);
  // const [notifications, setNotifications] = useState([]);
  // const [itemsCount, setItemsCount] = useState(0);

  const { isLoggedin } = props;

  const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
  const userNotifications: any = useSelector<IState>((state) => state.notifications) as {};
  const dispatch = useDispatch();

  useEffect(() => {
    apiGetSummary();
  }, []);

  const apiGetSummary = async () => {
    await notificationsSummary({ pageNumber: 1, pageSize: 3 }).then((response) => {
      if (response && response.data && response.data.items) {
        let summary = response.data.items;
        let itemsCount = response.data.metadata.totalItemsCount;
        dispatch(updateNotificationSummary(summary));
        dispatch(updateNotificationCount(itemsCount));
        // setNotifications(response.data.items);
        // setItemsCount(response.data.metadata.totalItemsCount);
      }
    });
  };

  const apiMarkAsRead = async (ids: any[]) => {
    dispatch(toggleLoader(true));

    await markNotificationAsRead({ ids })
      .then((response) => {
        if (response && response.data && response.status === 200) {
          apiGetSummary();
          dispatch(externalNotificationUpdate(userNotifications.externalUpdate + 1));
        }
      })
      .catch((err) => {
        if (err && err.status === 200) {
          apiGetSummary();
          dispatch(externalNotificationUpdate(userNotifications.externalUpdate + 1));
        }
      });

    dispatch(toggleLoader(false));
  };

  //@ts-ignore
  const handleClickOutside = (event) => {
    //@ts-ignore
    if (popupRef.current && !popupRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const renderNotificationButton = (isLoggedin: boolean) => {
    if (isLoggedin) {
      return (
        <div
          className="d-flex justify-content-center align-items-center notification-button"
          onClick={() => {
            setShowNotifications(!showNotifications);
          }}
          ref={buttonRef}
        >
          {userNotifications.summary && userNotifications.summary.length > 0 && <div className="notification-count">{userNotifications.itemsCount}</div>}
          <FontAwesomeIcon className="notification-icon" icon={outlineBell} size={'lg'} />
          {showNotifications && renderNotifications()}
        </div>
      );
    }
  };

  const renderNotifications = () => {
    return (
      <div className="notification-popup" ref={popupRef}>
        <div className="mb-3 n-popup-title">
          <span className="block-title-border" />
          <span className="block-title">Notificări</span>
        </div>
        <div className="n-popup-body d-flex justify-content-between flex-column">
          <div className="n-list">
            {userNotifications.summary.length > 0 &&
              userNotifications.summary.map((notification: any, index: number) => (
                <div className="notification" key={index}>
                  <div className="n-body">  
                    <span className="n-title">{notification.title}</span>
                  </div>
                  <div className="n-footer">
                    <span className="n-date">{moment.utc(notification.entryDate).local().format('YYYY-MM-DD HH:mm')}</span>
                    <div className="n-actions d-flex">
                      <div className="read-n" onClick={() => console.log('read')}>
                        <FontAwesomeIcon icon={faArrowRight} className="n-icon" />
                        <span>
                          <a href={`${OLD_SITE_URL}/notificari?read=${notification.id}`} className="red text-decoration-none hover-red">
                            Citește
                          </a>
                        </span>
                      </div>
                      <div className="mark-n" onClick={() => apiMarkAsRead([notification.id])}>
                        <FontAwesomeIcon icon={envelope} className="n-icon" />
                        <span>Marchează ca citit</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {userNotifications.summary.length === 0 && <div className="text-center">Nu există notificări noi.</div>}
          <div className="see-all">
            <FontAwesomeIcon icon={faArrowRight} className="n-icon" />
            <span>
              <a href={`${OLD_SITE_URL}/notificari`} className="red text-decoration-none hover-red">
                Vezi toate notificările
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  return <Fragment>{renderNotificationButton(isLoggedin)}</Fragment>;
};

export default UserNotifications;