import { Dropdown } from '@components/common/Dropdown';
import BackOfficeProvider, { BackOfficeContext, BackOfficeContextType, MetaData } from '@contexts/backoffice-context';
import { BackOfficeComponentProps } from '@kentico/backOffice';
import { ToString } from '@lib/Utils';
import { ActionIds, ActionType, ActionTypes, DataTypes, Field, Filter, ValueToSet } from '@type/backOfficeModule';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { getQueryParam } from '../../../helpers/urlUtils';
import useWindowDimensions from '../../../helpers/useWindowDimensions';
import ActionConfirm from './Actions/ActionConfirm';
import ActionModalForm from './Actions/ActionModalForm';
import CustomActionRss from './CustomActions/CustomActionRss';
import DetailedItem from './DetailedItem';
import ExportButton from './ExportButton';
import String from './Fields/String';
import FiltersForm, { FiltersCollapsedState } from './FiltersForm';
import useBoHelpers from './Hooks/useBoHelpers';
import ResponseListing from './ResponseListing';
import ModalComponent from '@components/dev/Components/Modal/Modal';

export const defaultEvent = { preventDefault: () => undefined, persist: () => undefined } as React.SyntheticEvent;

const PageWrapper = (props: BackOfficeComponentProps) => {
  const { downloadFileAction } = useBoHelpers();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [action, setAction] = useState<ActionType | null>(null);
  const {
    config,
    fetchResults,
    response,
    isFullWidth,
    detailedElement,
    searchModel,
    setSearchModel,
    onFilterValueChange,
    requiredFilterNotFound,
    clearSelected,
    isFilterInactive,
    conditionalConfig,
    isGeneralActionValidationTrue,
    selected,
    footerRef
  } = React.useContext(BackOfficeContext) as BackOfficeContextType;

  const { height } = useWindowDimensions();
  const [initialFiltersCollapsedState, setInitialFiltersCollapsedState] = useState<FiltersCollapsedState>({} as FiltersCollapsedState);
  const [searchRequested, setSearchRequested] = useState<boolean>(false);
  const [showGAValidationError, setShowGAValidationError] = useState<boolean>(false);
  const [GAValidationErrors, setGAValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    setInitialFiltersValues();
  }, [config]);

  useEffect(() => {
    if (searchRequested) {
      // remove inactive filters from searchModel
      const updatedSearchModel = {
        ...searchModel,
        Filters: searchModel.Filters.filter((f: Filter) => !isFilterInactive(f.Name)),
      };

      fetchResults(updatedSearchModel, true);
      setSearchRequested(false);
    }
  }, [searchRequested]);

  useEffect(() => {
    if (searchModel.SortColumn && searchModel.PageSize && searchModel.PageIndex) {
      // remove inactive filters from searchModel
      const updatedSearchModel = {
        ...searchModel,
        Filters: searchModel.Filters.filter((f: Filter) => !isFilterInactive(f.Name)),
      };

      fetchResults(updatedSearchModel, true);
    }
  }, [searchModel.SortAscending, searchModel.SortColumn, searchModel.PageSize, searchModel.PageIndex]);

  const resetFiltersToDefault = async () => {
    if (config) {
      setInitialFiltersValues();
      setSearchRequested(true);
    }
  };

  const removeFilter = (name: string) => {
    //@ts-ignore
    setSearchModel((prevStateSearchModel: any) => {
      return {
        ...prevStateSearchModel,
        Filters: prevStateSearchModel.Filters.filter((f: any) => f.Name !== name)
      };
    });
  };

  const setInitialFiltersValues = () => {
    if (config) {
      let filters: Filter[] = [];
      let initialFiltersCollapsedState: FiltersCollapsedState = {} as FiltersCollapsedState;

      config.filters
        .filter((item: Field) => item.value || item.dataTypeId === DataTypes.DateRange || item.initializeValueFromQueryString)
        .forEach((item: any, index: number) => {
          let queryParamFilterValue;
          let resetFilterValue;

          if (item.initializeValueFromQueryString) {
            queryParamFilterValue = getQueryParam(item.name.charAt(0).toLowerCase() + item.name.slice(1));
            //if the query param is present but has empty value is considered a reset for the filter
            resetFilterValue = queryParamFilterValue == '';
          }

          if (item.initializeValueFromQueryString && queryParamFilterValue) {
            if (queryParamFilterValue == '[]') return;
            filters.push({
              Name: item.name,
              Value: queryParamFilterValue,
              DataTypeId: item.dataTypeId
            } as Filter);
            initialFiltersCollapsedState[item.name] = true;
          } else if (item.value && !resetFilterValue) {
            filters.push({
              Name: item.name,
              Value: item.value,
              DataTypeId: item.dataTypeId
            } as Filter);
            initialFiltersCollapsedState[item.name] = true;
          }

          if (item.dataTypeId === DataTypes.DateRange && item.items) {
            let dateItemCollapsed = false;
            item.items.forEach((dateItem: any, index: number) => {
              let queryParamFilterValue;
              let resetFilterValue;

              if (dateItem.initializeValueFromQueryString) {
                queryParamFilterValue = getQueryParam(dateItem.name.charAt(0).toLowerCase() + dateItem.name.slice(1));
                resetFilterValue = queryParamFilterValue == '';
              }

              if (dateItem.initializeValueFromQueryString && queryParamFilterValue) {
                filters.push({
                  Name: dateItem.name,
                  Value: queryParamFilterValue,
                  DataTypeId: dateItem.dataTypeId
                } as Filter);
                dateItemCollapsed = true;
              } else if (dateItem.value && !resetFilterValue) {
                filters.push({
                  Name: dateItem.name,
                  Value: dateItem.value,
                  DataTypeId: dateItem.dataTypeId
                } as Filter);
                dateItemCollapsed = true;
              }
            });
            if (dateItemCollapsed) initialFiltersCollapsedState[item.name] = true;
          }
        });

      setSearchModel({
        ...searchModel,
        SortColumn: config.sortColumn,
        SortAscending: config.sortAscending,
        PageSize: config.pagination.defaultPageSizeValue,
        Filters: filters
      });

      setInitialFiltersCollapsedState(initialFiltersCollapsedState);
    }
  };

  const onValueGeneralChange = ({ name, value, itemTypeId }: ValueToSet) => {
    setSearchModel({
      ...searchModel,
      GeneralSearchText: ToString(value)
    });
  };

  const renderPage = (pagination: MetaData, i: number, showDots: boolean = false) => {
    return (
      <li
        key={i}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setSearchModel({ ...searchModel, PageIndex: i });
          clearSelected();
        }}
        className={i === pagination.pageIndex ? 'page-link-current' : 'page-link-not-current'}
        style={{ paddingLeft: '7px', paddingRight: '7px' }}
      >
        <a className={i === pagination.pageIndex ? 'current' : 'not-current'}>{showDots ? '...' : i}</a>
      </li>
    );
  };

  const renderPages = (pagination: MetaData) => {
    let pages = [];
    let totalPages: number = Math.ceil(pagination.totalItemsCount / pagination.pageSize);

    if (totalPages < 10) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(renderPage(pagination, i));
      }
    } else {
      let dottedPages: number[] = [];

      let otherPages: number[] = [];

      if (pagination.pageIndex > 0) {
        otherPages = [pagination.pageIndex - 1, pagination.pageIndex, pagination.pageIndex + 1].filter(
          (page: number) => page > 1 && page < totalPages - 1
        );
      }

      if (pagination.pageIndex >= totalPages - 2) {
        otherPages = [pagination.pageIndex - 2, pagination.pageIndex - 1, pagination.pageIndex].filter(
          (page: number) => page > 1 && page < totalPages - 1
        );
      }

      if (otherPages.length > 0) {
        if (otherPages[0] > 2) {
          dottedPages.push(otherPages[0] - 1);
        }

        if (otherPages[otherPages.length - 1] < totalPages - 2) {
          dottedPages.push(otherPages[otherPages.length - 1] + 1);
        }
      }

      let customPages: number[] = [1, ...otherPages, ...dottedPages, totalPages - 1, totalPages].sort((a, b) => a - b);

      for (let i = 0; i <= customPages.length - 1; i++) {
        pages.push(renderPage(pagination, customPages[i], dottedPages.includes(customPages[i])));
      }
    }

    return pages;
  };

  return (
    <div className="backoffice-section p-2">
      {config && (
        <>
          <Row>
            <Col md={2}>
              <div className="d-flex justify-content-center">
                {(config.filters.length > 0 || config.showGeneralSearchInput === true) && (
                  <>
                    <div className="">
                      <Button
                        type="submit"
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          // remove inactive filters from searchModel
                          const updatedSearchModel = {
                            ...searchModel,
                            Filters: searchModel.Filters.filter((f: Filter) => !isFilterInactive(f.Name)),
                            PageIndex: 1
                          };

                          // setSearchModel(updatedSearchModel);
                          fetchResults(updatedSearchModel, true);
                        }}
                        className="btn btn-primary mt-2 mb-2 btn-md btn-submit w-100"
                        disabled={requiredFilterNotFound}
                      >
                        Cauta
                      </Button>
                    </div>
                    <div className="">
                      <Button
                        variant="secondary"
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          resetFiltersToDefault();
                        }}
                        className="mt-2 mb-2 ms-2 btn-md w-100"
                      >
                        Resetare
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Col>
            <Col md={10} className="px-4">
              <div className="d-flex flex-wrap">
                <ExportButton />

                {conditionalConfig &&
                  conditionalConfig.generalActions &&
                  conditionalConfig.generalActions.map((action: ActionType, key: number) => {
                    return (
                      <Button
                        key={key}
                        className="btn btn-primary me-1 mt-2 mb-2 btn-md "
                        onClick={(e) => {
                          if (action.allowMultipleSelect === true) {
                            let GAValidations = isGeneralActionValidationTrue(action);
                            
                            const allValid = Array.isArray(GAValidations)
                              ? GAValidations.every((item: { isValid: boolean }) => item.isValid === true)
                              : GAValidations.isValid === true;
                          
                            if (allValid) {
                              setAction(action);
                              setIsEditing(true);
                              if (
                                action.actionTypeName === 'DownloadInternalAction' ||
                                action.actionTypeName === 'DownloadInternal' ||
                                action.actionTypeName === 'DownloadExternal'
                              ) {
                                downloadFileAction(action);
                              }
                            } else {
                              const errorMessages = Array.isArray(GAValidations)
                                ? GAValidations.map((item: { isValid: boolean, errorMessage: string }) => item.errorMessage)
                                : [GAValidations.errorMessage];
                          
                              setGAValidationErrors(errorMessages);
                              setShowGAValidationError(true);
                            }
                          } else {
                            setAction(action);
                            setIsEditing(true);
                            if (
                              action.actionTypeName === 'DownloadInternalAction' ||
                              action.actionTypeName === 'DownloadInternal' ||
                              action.actionTypeName === 'DownloadExternal'
                            ) {
                              downloadFileAction(action);
                            }
                          }                          
                        }}
                        disabled={action.allowMultipleSelect && selected.length < 1}
                        variant="primary"
                      >
                        {action.displayName}
                      </Button>
                    );
                  })}
              </div>
            </Col>
            <Col md={2} sm={12}>
              {config.showGeneralSearchInput === true && (
                <Row>
                  <Col>
                    <String
                      label="Cautare Generala"
                      value={searchModel.GeneralSearchText ?? ''}
                      name={'GeneralSearchText'}
                      onChange={onValueGeneralChange}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Row>
                  {requiredFilterNotFound && (
                    <p className="m-0 ms-3 red fw-bold text-center">Vă rugăm să introduceți valori pentru toate filtrele obligatorii.</p>
                  )}
                </Row>
                {searchModel.Filters && <FiltersForm
                  filters={searchModel.Filters}
                  onValueChange={onFilterValueChange}
                  removeFilter={removeFilter}
                  initialFiltersCollapsedState={initialFiltersCollapsedState}
                />}
              </Row>
              {config.filters.length > 5 ? (
                <div className="d-flex justify-content-center">
                  {(config.filters.length > 0 || config.showGeneralSearchInput === true) && (
                    <>
                      <div className="">
                        <Button
                          type="submit"
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            // remove inactive filters from searchModel
                            const updatedSearchModel = {
                              ...searchModel,
                              Filters: searchModel.Filters.filter((f: Filter) => !isFilterInactive(f.Name)),
                              PageIndex: 1
                            };

                            // setSearchModel(updatedSearchModel);
                            fetchResults(updatedSearchModel, true);
                          }}
                          className="btn btn-primary mt-2 mb-2 btn-md btn-submit w-100"
                          disabled={requiredFilterNotFound}
                        >
                          Cauta
                        </Button>
                      </div>
                      <div className="">
                        <Button
                          variant="secondary"
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            resetFiltersToDefault();
                          }}
                          className="mt-2 mb-2 ms-2 btn-md w-100"
                        >
                          Resetare
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                ''
              )}
            </Col>

            <Col className="px-4" sm={12} md={isFullWidth ? 10 : 7}>
              <Row>
                <Col className="px-2" sm={12}>
                  <ResponseListing
                    response={response}
                    searchModel={searchModel}
                    setSearchModel={(value: any) => setSearchModel(value)}
                    onSort={(column: string) => {
                      setSearchModel({
                        ...searchModel,
                        SortAscending: searchModel.SortColumn === column ? !searchModel.SortAscending : true,
                        SortColumn: column
                      });
                    }}
                    renderPages={renderPages}
                  />
                  {response && response.items.length > 0 && config.pagination && config.pagination.active === true && (
                    <Row className="mt-4" ref={footerRef}>
                      <Col md={2} sm={12}>
                        <Dropdown
                          options={config.pagination.pageSizeOptionValues.map((item: number) => ({
                            id: ToString(item),
                            name: ToString(item)
                          }))}
                          label={'Elemente pagina'}
                          value={ToString(searchModel.PageSize) ?? ''}
                          onChange={(value) => {
                            setSearchModel({
                              ...searchModel,
                              PageSize: parseInt(value),
                              PageIndex: 1
                            });
                          }}
                        />
                      </Col>
                      {response.metadata && response.metadata.pageSize <= response.metadata.totalItemsCount && (
                        <Col md={9} sm={12}>
                          <nav>
                            <ul className="pagination">
                              <span
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                  if (searchModel.PageIndex > 1) setSearchModel({ ...searchModel, PageIndex: 1 });
                                  clearSelected();
                                }}
                                className={response.metadata.pageSize === 1 ? 'nav-icon-disabled' : 'nav-icon'}
                              >
                                «
                              </span>

                              <span
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                  if (searchModel.PageIndex > 1) setSearchModel({ ...searchModel, PageIndex: searchModel.PageIndex - 1 });
                                  clearSelected();
                                }}
                                className={response.metadata.pageSize === 1 ? 'nav-icon-disabled' : 'nav-icon'}
                              >
                                &lt;
                              </span>
                              {renderPages(response.metadata)}
                              <span
                                className="nav-icon"
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                  if (response.metadata && response.metadata.totalItemsCount && response.metadata.pageSize) {
                                    let totalPages: number = Math.ceil(response.metadata.totalItemsCount / response.metadata.pageSize);
                                    if (response.metadata.pageIndex < totalPages)
                                      setSearchModel({ ...searchModel, PageIndex: response.metadata.pageIndex + 1 });
                                    clearSelected();
                                  }
                                }}
                              >
                                &gt;
                              </span>

                              <span
                                className="nav-icon"
                                onClick={(event: React.MouseEvent<HTMLElement>) => {
                                  if (response.metadata && response.metadata.totalItemsCount && response.metadata.pageSize) {
                                    let totalPages: number = Math.ceil(response.metadata.totalItemsCount / response.metadata.pageSize);
                                    if (response.metadata.pageIndex < totalPages) setSearchModel({ ...searchModel, PageIndex: totalPages });
                                    clearSelected();
                                  }
                                }}
                              >
                                »
                              </span>
                            </ul>
                          </nav>
                        </Col>
                      )}
                    </Row>
                  )}
                  {isFullWidth && <Row className="m-5 p-5"></Row>}
                  {height > 720 && <Row className="pagination-spacer" />}
                </Col>
              </Row>
            </Col>

            {!isFullWidth && <Col md={3}>{detailedElement && <DetailedItem detailedElement={detailedElement} />}</Col>}
          </Row>
        </>
      )}

      {action && action.actionTypeName === ActionTypes.ModalForm && (
        <ActionModalForm action={action} isEditing={isEditing} setIsEditing={setIsEditing} setAction={setAction} isRowAction={false} />
      )}

      {action?.actionTypeName === ActionTypes.Confirm && (
        <ActionConfirm action={action} isEditing={isEditing} setIsEditing={setIsEditing} isRowAction={false} />
      )}

      {action?.actionTypeName == ActionTypes.FrontEndImplementation && action?.actionId == ActionIds.RSS_CREATE && (
        <CustomActionRss action={action} isEditing={isEditing} setIsEditing={setIsEditing} />
      )}

      {showGAValidationError && 
        <ModalComponent 
          show={showGAValidationError} 
          setShow={(value: boolean) => setShowGAValidationError(value)} 
          title="Eroare"
        >
          <>
            {GAValidationErrors.map((error: string, index: number) => {
              return <span key={index}>{error}</span>;
            })}
          </>
        </ModalComponent>
      }
    </div>
  );
};

export default function PageContexted(props: BackOfficeComponentProps) {
  return (
    <BackOfficeProvider baseUrl={props.kentico.baseUrl} securityContext={props.kentico.securityContext}>
      <PageWrapper {...props} />
    </BackOfficeProvider>
  );
}