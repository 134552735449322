import { toggleLoader } from '@actions/LoaderActions';
import { save } from '@api/autocitiri/autocitiriClientLogat';
import { Input } from '@components/common/Input';
import { UploadFile } from '@components/common/UploadFile';
import { File } from '@components/common/UploadFile/UploadFile';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const ModalAutocitiriClientLogat = (props: {
  handleShow: any;
  show: boolean;
  modalData: any;
  clearModalData: any;
  handleSuccessModal: any;
  consumptionPointId: any;
  consumptionPointCode: any;
  setIsLastDay: any;
}) => {
  //eslint-disable-next-line
  const [fileList, setFileList] = useState<File[]>([] as File[]);
  //eslint-disable-next-line
  const [message, setMessage] = useState('');
  //eslint-disable-next-line
  const [acord1, setAcord1] = useState(false);
  //eslint-disable-next-line
  const [acord2, setAcord2] = useState(false);
  //eslint-disable-next-line
  const [messageError, setMessageError] = useState('');
  const [error, setError] = useState('');
  const [indecsi, setIndecsi] = useState<any>(null);
  const [indexError, setIndexError] = useState('');
  //eslint-disable-next-line
  const [wasVerified, setWasVerified] = useState(false);
  //eslint-disable-next-line
  const [lastIndex, setLastIndex] = useState<any>(0);
  const messageRef = useRef(null);
  //eslint-disable-next-line
  const [placeholder, setPlaceholder] = useState('Scrie-ne perioada în care te putem contacta pentru citirea contorului.');
  // const [showNoIndexConfirmModal, setShowNoIndexConfirmModal] = useState(false);
  // const [form, setForm] = useState<any>(null);
  //eslint-disable-next-line
  const [captchaInstanceId, setCaptchaInstanceId] = useState<string>('');
  const [showDiffModal, setShowDiffModal] = useState(false);

  const { handleShow, show, modalData, handleSuccessModal, setIsLastDay } = props;

  const dispatch = useDispatch();

  //@ts-ignore
  const handleClickOutside = (event) => {
    //@ts-ignore
    if (messageRef.current && !messageRef.current.contains(event.target)) {
      setPlaceholder('Scrie-ne perioada în care te putem contacta pentru citirea contorului.');
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (modalData && modalData.devices) {
      let dev = JSON.parse(JSON.stringify(sortDevice(modalData.devices)));
      dev?.map((device: any, eIndex: number) => {
        device.indexes.map((r: any, rIndex: number) => {
          delete r['indexMax'];
          delete r['indexMin'];
          // delete r['indexOld'];
          r.error = '';
          r.document = {
            NumeDocument: r.document?.fileName || '',
            ContinutDocument: r.document?.documentLink
              ? [
                  {
                    base64: r.document.documentLink,
                    name: r.document.fileName
                  }
                ]
              : []
          };
          r.indexNew = r.indexNew || '';
        });
      });

      setIndecsi(dev);
    }
  }, [modalData]);

  const handleFileListChange = (files: File[], deviceIndex: number, index: number) => {
    dispatch(toggleLoader(true));
    let ind = sortDevice(indecsi);

    ind?.map((e: any, eIndex: number) => {
      e.indexes.map((r: any, rIndex: number) => {
        if (eIndex === deviceIndex && rIndex === index) {
          r.document = {
            NumeDocument: files[0]?.name || '',
            ContinutDocument: files
          };
        }
      });
    });

    setIndecsi([...ind]);
    dispatch(toggleLoader(false));
  };

  const constructPayload = () => {
    let devices: any[] = [];

    indecsi.map((index: any, i: number) => {
      let item: any = {
        equipmentNumber: indecsi[i].equipmentNumber,
        indexes: []
      };

      index.indexes.map((idx: any, indx: number) => {
        let newItem: any = {
          ablbelnr: idx.ablbelnr,
          indexNew: Number(idx.indexNew),
          document:
            (idx.document.ContinutDocument.length > 0 && !idx.document.ContinutDocument[0]?.base64.includes('http'))
              ? {
                  NumeDocument: idx.document.NumeDocument,
                  ContinutDocument: idx.document.ContinutDocument[0]?.base64.split(',')[1] || ''
                }
              : null
        };
        if (idx.document.ContinutDocument.length === 0) {
          newItem.DeletePreviousDocument = true;
        }

        item.indexes.push(newItem);
      });

      devices.push(item);
    });

    return {
      consumptionPointId: props.consumptionPointId,
      consumptionPointCode: props.consumptionPointCode,
      devices: devices
    };
  };

  const submit = (values?: any) => {
    setMessageError('');
    setError('');

    if (checkIndexes(sortDevice(indecsi))) {
      if (checkIndexDifference() === false) {
        apiCall(constructPayload());
      } else {
        handleShow(false);
        setShowDiffModal(true);
      }
    }
  };

  const apiCall = async (payload: any) => {
    dispatch(toggleLoader(true));
    setIsLastDay(false);

    await save(payload, modalData.pod[0] === 'E' ? 1 : 2)
      .then((response) => {
        if (response) {
          if (response.errorMessage && response.errorMessage !== '') {
            setError(response.errorMessage);
            setShowDiffModal(false);
            handleShow(true);
          } else if (response && response.success && response.success === true) {
            setIsLastDay(response.isLastDay);
            handleSuccessModal(true);
            setMessage('');
            setAcord1(false);
            setAcord2(false);
            setMessageError('');
            setIndexError('');
            setError('');
            setFileList([]);
            handleShow(false);
            setShowDiffModal(false);
          }
        }
      })
      .catch((err) => {
        setError(err);
      });

    dispatch(toggleLoader(false));
  };

  //eslint-disable-next-line
  const areAllIndexesEmpty = (indexes: any) => {
    let empty = true;

    indexes.map((ind: any, index: number) => {
      ind.indexes.map((registru: any, idx: number) => {
        if (registru.indexNew !== '') {
          empty = false;
        }
      });
    });

    return empty;
  };

  const checkIndexDifference = () => {
    let e = sortDevice(indecsi);
    let foundDiff = false;

    e.map((equipment: any, index: number) => {
      equipment.indexes.map((registru: any, idx: number) => {
        if (Math.abs(Number(registru.indexNew) - Number(registru.indexOld)) > 2000) {
          foundDiff = true;
          registru.error = ' ';
        }
      });
    });

    setIndecsi([...e]);

    return foundDiff;
  };

  const checkIndexes = (indexes: any) => {
    let e = sortDevice(indecsi);

    console.log('e: ', e);

    // Clear all errors
    e.map((equipment: any, index: number) => {
      equipment.indexes.map((registru: any, idx: number) => {
        registru.error = '';
      });
    });

    let utility = modalData.pod[0] === 'E' ? 1 : 2;

    // Validation 1 - Check if indexes have correct value
    e.map((equipment: any, index: number) => {
      equipment.indexes.map((registru: any, idx: number) => {
        if (registru.indexNew < sortDevice(modalData.devices)[index].indexes[idx].indexMin && registru.indexNew !== '') {
          registru.error = 'Index nou trebuie să fie mai mare sau egal cu indexul vechi.';
        }

        // if (registru.indexNew > modalData.devices[index].indexes[idx].indexMax && registru.indexNew !== '') {
        //   registru.error = `Cantitatea rezultată nu trebuie să depășească ${modalData.devices[index].indexes[idx].indexMax} ${
        //     utility === 1 ? 'kWh' : 'mc'
        //   }.`;
        // }

        if (registru.indexNew > sortDevice(modalData.devices)[index].indexes[idx].indexMax && registru.indexNew !== '') {
          registru.error = `Cantitatea rezultată nu trebuie să depășească ${utility === 1 ? '20000 kWh' : ' 20000 mc'}.`;
        }

        if (registru.indexNew === '') {
          registru.error = 'Index nou este obligatoriu.';
        }
      });
    });

    setIndecsi([...e]);

    // Check if all indexes passed the validations
    let isValid = true;

    e.map((equipment: any, index: number) => {
      equipment.indexes.map((registru: any, idx: number) => {
        if (registru.error !== '') {
          isValid = false;
          setError('Te rugăm să verifici informațiile transmise.');
        }
      });
    });

    return isValid;
  };

  const onIndexChange = (value: string, index: number, idx: number) => {
    let ind = sortDevice(indecsi);

    ind?.map((e: any, eIndex: number) => {
      e.indexes.map((r: any, rIndex: number) => {
        if (eIndex === index && rIndex === idx) {
          r.indexNew = value;
        }
      });
    });

    setIndecsi([...ind]);
  };

  const sortDevice = (data: any) => {
    const sortedData = data.map((item: any) => ({
      ...item,
      indexes: [...item.indexes].sort((a, b) => {
        const [majorA, minorA, patchA] = a.registrytype.split('.').map(Number);
        const [majorB, minorB, patchB] = b.registrytype.split('.').map(Number);
  
        return (
          majorA - majorB ||
          minorA - minorB ||
          patchA - patchB
        );
      }),
    }));

    return sortedData;
  };

  const renderDevices = () => {
    return (
      <div className="mt-2 mb-2">
        {sortDevice(indecsi).map((device: any, index: number) => {
          return (
            <div key={index}>
              {device.indexes.map((registru: any, idx: number) => {
                return (
                  <div className="equipment mt-0 mb-4" key={idx}>
                    <Row>
                      <Col className="mt-4">
                        <Input value={registru.registrytype} label="Registru" disabled mbZero />
                      </Col>
                      <Col className="mt-4">
                        <Input value={sortDevice(modalData.devices)[index].indexes[idx].indexOld} label="Index vechi" disabled mbZero />
                      </Col>
                      <Col className="mt-4">
                        <Input
                          value={registru.indexNew}
                          label="Index nou*"
                          onChange={(e) => {
                            if (/^[0-9]+$/.test(e.target.value) || e.target.value === '') {
                              onIndexChange(e.target.value, index, idx);
                            }
                          }}
                          mbZero
                          className={registru.error !== '' ? 'forced-focus' : ''}
                        />
                      </Col>
                    </Row>
                    {registru.error !== '' && (
                      <Row>
                        <div className="fw-bold red text-center">{registru.error}</div>
                      </Row>
                    )}
                    <div className="mt-2 mb-4">
                      <div className="mb-2">
                        <span>Atașează poza la indexul citit pe registrul contorului.</span>
                      </div>
                      <UploadFile
                        fileList={indecsi[index].indexes[idx].document.ContinutDocument}
                        onSetFileList={(list: File[]) => handleFileListChange(list, index, idx)}
                        index={0}
                        uploadedFilesLimit={1}
                        acceptedExtensions={['.bmp', '.gif', '.jpg', '.jpeg', '.tiff', '.tif', '.png']}
                      />
                      <span className="property-label" style={{ fontSize: '14px' }}>
                        Se acceptă documente în format BMP, GIF, JPG, JPEG, TIFF, TIF, PNG. Documentele nu trebuie să depășească limita
                        maximă de 4MB/fișier.
                      </span>
                    </div>
                    <div className="separator"></div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  //eslint-disable-next-line
  const verifyIndex = () => {
    setIndexError('');
    setWasVerified(true);

    indecsi.map((ind: any, index: number) => {
      ind.indexes.map((r: any, idx: number) => {
        if (r.indexNew < modalData.devices[index].indexes[idx].indexMin) {
          setWasVerified(false);
          setIndexError('Valoarea acestuia trebuie să fie mai mare (sau egal) decât ultimul index înregistrat - ');
        }
      });
    });
  };

  const renderDiffModal = () => {
    return (
      <Modal
        show={showDiffModal}
        centered
        backdrop={'static'}
        onHide={() => {
          setShowDiffModal(false);
          handleShow(true);
        }}
      >
        <Modal.Header closeButton>
          <span style={{ height: '30px' }}></span>
        </Modal.Header>
        <Modal.Body>
          <p>
            {`Cantitatea rezultată depășește ${modalData.pod[0] === 'E' ? '2000 kWh' : ' 2000 mc'}, te rugăm să confirmi indexul introdus.`}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => apiCall(constructPayload())}>Confirm index</Button>
          <Button
            onClick={() => {
              setShowDiffModal(false);
              handleShow(true);
            }}
          >
            Modific index
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Fragment>
      <Modal
        show={show}
        backdrop={'static'}
        onHide={() => {
          handleShow(false);
          setMessage('');
          setAcord1(false);
          setAcord2(false);
          setMessageError('');
          setIndexError('');
          setError('');
          setFileList([]);
        }}
        className="meter-reading-modal"
      >
        <Modal.Header closeButton>
          <div className="p-0">
            <h5 className="m-0 modal-title">Transmitere index autocitit</h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="client-type-section mb-0">
            <Row className="client-type-section-header">
              <Col xs={12} sm={6}>
                <div className="section-red-border" />
                <h5 className="m-0">Date de identificare loc de consum</h5>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="separator" />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} sm={6} className="flex-column d-flex">
                <Input value={modalData.clientName !== null ? modalData.clientName : ''} disabled label="Nume și prenume/Denumire client" />
              </Col>
              <Col xs={12} sm={6} className="flex-column d-flex">
                <Input value={modalData.consumptionPointCode} disabled label="Cod loc consum" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} className="flex-column d-flex">
                <Input value={modalData.pod} disabled label="POD" />
              </Col>
              <Col xs={12} sm={6} className="flex-column d-flex">
                <Input value={modalData.address} disabled label="Adresă loc consum" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} className="flex-column d-flex">
                <Input
                  value={modalData.devices && modalData.devices.length > 0 ? modalData.devices[0].equipmentNumber : ''}
                  disabled
                  label="Echipament"
                />
              </Col>
              <Col xs={12} sm={4} className="flex-column d-flex">
                <Input
                  value={modalData.devices && modalData.devices.length > 0 ? modalData.devices[0].meterType : ''}
                  disabled
                  label="Material"
                />
              </Col>
              <Col xs={12} sm={4} className="flex-column d-flex">
                <Input
                  value={modalData.devices && modalData.devices.length > 0 ? modalData.devices[0].meterSeries : ''}
                  disabled
                  label="Serie"
                />
              </Col>
            </Row>
          </div>
          <div className="client-type-section mt-4">
            <Row className="client-type-section-header">
              <Col xs={12} sm={4}>
                <div className="section-red-border" />
                <h5 className="m-0">Indexul tău</h5>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xs={12}>
                <div className="separator" />
              </Col>
            </Row>
            {/* {modalData && modalData.echipamente && renderEquipments(modalData.echipamente)} */}
            {indecsi && indecsi.length > 0 && renderDevices()}

            {indexError !== '' && (
              <p className="red fw-bold">
                {indexError} {lastIndex}
              </p>
            )}
          </div>
          <div className="bottom-section mb-4">
            <span>
              După salvarea cu succes, poți modifica sau șterge indexul autocitit în următoarele 24 de ore. Dacă perioada alocată pentru
              autocitire se încheie mai devreme, poți edita autocitirea până la sfârșitul perioadei. După acest interval, autocitirea se
              transmite și nu mai poate fi modificată.
            </span>
            {error !== '' && <p className="fw-bold red mt-3">{error}</p>}
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                style={{ marginRight: '8px' }}
                onClick={() => {
                  handleShow(false);
                  setMessage('');
                  setAcord1(false);
                  setAcord2(false);
                  setMessageError('');
                  setIndexError('');
                  setError('');
                  setFileList([]);
                }}
              >
                Renunță
              </Button>
              <Button onClick={() => submit()}>Salvează</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {renderDiffModal()}
    </Fragment>
  );
};

export default ModalAutocitiriClientLogat;