import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Device, HistorySelfReadingsUserItem, Index } from '@type/selfReadings';
import { IState } from '@type/store';
import moment from 'moment';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import useFileDownloader from '../../hooks/useFileDownloader';
import { Col, Row } from 'react-bootstrap';

const TableItem = (props: { item: HistorySelfReadingsUserItem; handleIsCollapsed: any; getDetails: any; utility: number }) => {
  const { item, handleIsCollapsed, getDetails, utility } = props;
  const apiHost: string | undefined = useSelector<IState>((state) => state.options.apiHost) as string;

  //eslint-disable-next-line
  const { downloadFile, downloadError } = useFileDownloader();

  const downloadDocumentDeviceRegistry = async (documentDeviceRegistryId: number, fileName: string) => {
    downloadFile(
      `${apiHost}/self-reading/SelfReadings/consumption_point_history_details_user_download?utility=${
        utility || 1
      }&deviceRegistryId=${documentDeviceRegistryId}`,
      fileName
    );
  };

  return (
    <Fragment>
      <tr
        style={{ cursor: 'pointer' }}
        onClick={() => {
          item.isCollapsed === true && getDetails(item.consumptionPointId);
          handleIsCollapsed(item.consumptionPointId, !item.isCollapsed);
        }}
      >
        <td>
          <span>{item.clientName || '-'}</span>
        </td>
        <td>
          <span>{item.address || '-'}</span>
        </td>
        <td>
          <span>{item.pod || '-'}</span>
        </td>
        <td>
          <span>{item.consumptionPointCode || '-'}</span>
        </td>
        <td>
          <div className="d-flex align-items-center justify-content-between">
            <span style={{ marginRight: '16px' }}>{moment(item.updateDate).format('DD.MM.YYYY') || '-'}</span>
            {item.isCollapsed && <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: '8px' }} />}
            {!item.isCollapsed && <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: '8px' }} />}
          </div>
        </td>
      </tr>
      {!item.isCollapsed &&
        item.devices &&
        item.devices.map((device: Device, i: number) => {
          return device.indexes.map((deviceIndex: Index, j: number) => {
            return (
              <tr style={{ background: '#fff' }} key={`${i}${j}`}>
                <td colSpan={5} style={{ background: '#fff' }}>
                  <Row className="p-3 m-2">
                    <Col md={3} className="align-items-center">
                      <span className="fw-bold me-1">Serie contor: </span>
                      <span>{device.meterSeries || '-'}</span>
                    </Col>

                    <Col md={3} className="align-items-center">
                      <span className="fw-bold me-1">Registrul: </span>
                      <span>{deviceIndex.registrycode + deviceIndex.registrytype || '-'}</span>
                    </Col>

                    <Col md={3} className="align-items-center">
                      <span className="fw-bold me-1">Index transmis: </span>
                      <span>{deviceIndex.indexNew || '-'}</span>
                    </Col>

                    <Col md={3} className="align-items-center">
                      <span className="fw-bold me-1">Document: </span>
                      <span>
                        {deviceIndex.document ? (
                          <a
                            href={deviceIndex.document.documentLink}
                            target="_blank"
                            rel="noreferrer"
                            style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                          >
                            {deviceIndex.document.fileName}
                          </a>
                        ) : deviceIndex.documentDeviceRegistryId ? (
                          <a
                            onClick={() => downloadDocumentDeviceRegistry(deviceIndex.documentDeviceRegistryId, deviceIndex.fileName)}
                            style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                            href="#"
                          >
                            {deviceIndex.fileName}
                          </a>
                        ) : (
                          '-'
                        )}
                      </span>
                    </Col>
                  </Row>
                </td>
              </tr>
            );
          });
        })}
    </Fragment>
  );
};

export default TableItem;