import React from 'react';

import ModalComponent from '@components/dev/Components/Modal/Modal';
import TimelineComponent from '@components/timeline/TimelineComponent';
import { BackOfficeContext, BackOfficeContextType } from '@contexts/backoffice-context';
import { ActionType, ModalSize } from '@type/backOfficeModule';
import { IState } from '@type/store';
import { useSelector } from 'react-redux';
import useBoHelpers from '../Hooks/useBoHelpers';

type CustomActionTimelineProps = {
  action: ActionType;
  isEditing: boolean;
  setIsEditing: (val: boolean) => void;
  item: any; //only for row actions
};

const CustomActionTimeline = (props: CustomActionTimelineProps) => {
  const { action, isEditing, item } = props;
  const { setIsEditing } = props;
  const { getModalSize } = useBoHelpers();

  const { config } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const basePath: any = useSelector<IState>((state) => state.backOffice.basePath);

  return (
    <ModalComponent
      title={action.displayName}
      show={isEditing}
      className="backoffice-modal"
      setShow={(state: boolean) => {
        setIsEditing(!isEditing);
      }}
      fullScreen={action.modalSize && action.modalSize == ModalSize.FullScreen ? true : 'md-down'}
      size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
    >
      <div className="mt-2">
        <TimelineComponent
          requestId={config ? item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)] : ''}
          isBo={true}
          boBasePath={basePath}
          utility={item['segment'] == 'EE' ? '1' : '2'}
          processStatus={item['processStatus']}
        />
      </div>
    </ModalComponent>
  );
};

export default CustomActionTimeline;
