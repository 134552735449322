import React, { useState } from 'react';

import { Button } from 'react-bootstrap';

import { toggleLoader } from '@actions/LoaderActions';
import { Export } from '@api/backOffice/fetchResults';
import { BackOfficeContext, BackOfficeContextType } from '@contexts/backoffice-context';
import { DataTypes, Filter } from '@type/backOfficeModule';
import { useDispatch } from 'react-redux';

import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import * as notification from '@lib/Notification';
import moment from 'moment';

const ExportButton = () => {
  const { config, response, searchModel, requiredFilterNotFound } = React.useContext(BackOfficeContext) as BackOfficeContextType;

  const dispatch = useDispatch();

  const [showModalExportLimit, setShowModalExportLimit] = useState<boolean>(false);

  const exportFiles = async () => {
    //limit export / default 500.000
    const rowsCountMaxLimit = config?.exportButton.rowsCountMaxLimit ?? 500000;
    const totalCountInList = response?.metadata?.totalItemsCount ?? 0;

    if (rowsCountMaxLimit > 0 && totalCountInList > rowsCountMaxLimit) {
      setShowModalExportLimit(true);
    } else {
      dispatch(toggleLoader(true));

      //convert all values to string
      //exclude "[]"
      await Export({
        ...searchModel,
        Filters: searchModel.Filters.filter((filter: Filter) => {
          if (
            (filter.DataTypeId == DataTypes.MultiSelectDropdownInt || filter.DataTypeId == DataTypes.MultiSelectDropdownString) &&
            filter.Value == '[]'
          ) {
            return false;
          }
          return true;
        }).map((filter: Filter) => {
          let stringValue;
          if (typeof filter.Value !== 'string') {
            stringValue = filter.Value + '';
          } else {
            stringValue = filter.Value;
          }
          return { ...filter, Value: stringValue };
        })
      })
        .then((res: any) => {
          const contentType = res?.headers['content-type'];
          setTimeout(() => {
            const blob: Blob = new Blob([res.data]);
            let fileName: string = '';
            if (config) {
              fileName = config.exportButton.fileName.replace('{timestamp}', moment().format('YYMMDDHms'));
              if (!contentType.includes('spreadsheetml.sheet')) {
                fileName = fileName.replace('.xlsx', '.zip');
              }
            } else {
              fileName = `export-${moment().format('YYMMDDHms')}.xlsx`;
            }
            const objectUrl: string = URL.createObjectURL(blob);
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

            a.href = objectUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            URL.revokeObjectURL(objectUrl);
            dispatch(toggleLoader(false));
          }, 500);
        })
        .catch((error) => {
          dispatch(toggleLoader(false));
          try {
            const decoder = new TextDecoder('utf-8');
            const decodedString = decoder.decode(error);
            const obj = JSON.parse(decodedString);
            const status = obj.status;
            if (status == 401) {
              notification.error('Sesiunea a expirat. Pagina se va reâncărca automat');
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            } else {
              notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
            }
          } catch (error) {
            notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
        });
    }
  };

  return (
    <>
      {config?.exportButton && config.exportButton.active === true && (
        <Button
          type="button"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            exportFiles();
          }}
          className="btn btn-primary me-1 mt-2 mb-2 btn-md btn-submit"
          disabled={requiredFilterNotFound}
        >
          {config.exportButton.buttonDisplayName}
        </Button>
      )}

      <ModalConfirm showModal={showModalExportLimit} modalTitle="Eroare!" onClose={() => setShowModalExportLimit(false)}>
        <>
          <p>Setul de date selectat depășește limita admisă pentru export. Vă rugăm să aplicați filtrări suplimentare.</p>
          <p>
            Limita admisă pentru exportul curent este <strong>{config?.exportButton.rowsCountMaxLimit ?? 500000}</strong>
          </p>
        </>
      </ModalConfirm>
    </>
  );
};

export default ExportButton;
