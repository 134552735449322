import React, { useRef, useState } from 'react';

import ModalComponent from '@components/dev/Components/Modal/Modal';
import RSSCallCenter from '@components/rssCallCenter/RSSCallCenter';
import { BackOfficeContext, BackOfficeContextType } from '@contexts/backoffice-context';
import { ActionType, ModalSize } from '@type/backOfficeModule';
import { Button } from 'react-bootstrap';
import useBoHelpers from '../Hooks/useBoHelpers';

type CustomActionRssProps = {
  action: ActionType;
  isEditing: boolean;
  setIsEditing: (val: boolean) => void;
};

const CustomActionRss = (props: CustomActionRssProps) => {
  const [rssRegisteredWithSucces, setRssRegisteredWithSucces] = useState<boolean>(false);
  const [showRSSSaveButton, setShowRSSSaveButton] = useState<boolean>(false);
  const [RSSSaveButtonDisabled, setRSSSaveButtonDisabled] = useState<boolean>(false);
  const RSSCallCenterRef = useRef(null);

  const { action, isEditing } = props;
  const { setIsEditing } = props;
  const { getModalSize } = useBoHelpers();

  const { config } = React.useContext(BackOfficeContext) as BackOfficeContextType;

  return (
    <ModalComponent
      title={action.displayName}
      show={isEditing}
      className="backoffice-modal"
      setShow={(state: boolean) => {
        setIsEditing(!isEditing);
        setRssRegisteredWithSucces(false);
      }}
      closeButttonLabel={`${rssRegisteredWithSucces ? 'OK' : ''}`}
      fullScreen={action.modalSize && action.modalSize == ModalSize.FullScreen ? true : 'md-down'}
      size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
      footerAction={
        !rssRegisteredWithSucces && showRSSSaveButton ? (
          <Button
            className="btn btn-primary"
            disabled={RSSSaveButtonDisabled}
            onClick={() => {
              //@ts-ignore
              RSSCallCenterRef.current?.submitFromBo && RSSCallCenterRef.current?.submitFromBo();
            }}
            variant="primary"
          >
            Salveaza
          </Button>
        ) : undefined
      }
      backdropStatic
    >
      <div className="mt-2">
        <RSSCallCenter
          userRoles={config?.loggedInUserRoles ?? []}
          ref={RSSCallCenterRef}
          setShowRSSSaveButton={setShowRSSSaveButton}
          setDisableRSSSaveButton={setRSSSaveButtonDisabled}
          onRegisterRssSuccesBo={() => setRssRegisteredWithSucces(true)}
        />
      </div>
    </ModalComponent>
  );
};

export default CustomActionRss;
