import {store} from '@lib/store';
import axios from 'axios';

export function getSelfReadingsHistory(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/self-reading/SelfReadings/history_selfreadings_user?utility=${payload.utility || '1'}&pod=${payload.pod || ''}&clc=${payload.clc || ''}&startDate=${payload.startDate || ''}&endDate=${payload.endDate || ''}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};

export function getSelfReadingsHistoryDetails(payload: any) {
    const state = store.getState();

    return axios.get(
        `${state.options.apiHost}/self-reading/SelfReadings/consumption_point_history_details_user?utility=${payload.utility || '1'}&consumptionPointId=${payload.consumptionPointId || ''}`,
        {
            headers: {
                'Authorization': 'Bearer ' + state.auth.token,
                'Ocp-Apim-Subscription-Key': state.options.subscriptionKey as string
            }
        }
    );
};