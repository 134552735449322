import React, { useEffect, useRef } from 'react';
import { Input } from '@components/common/Input';
import { InputTypes } from '@constants/InputTypes';
import { ValueToSet } from '@type/backOfficeModule';

type IntProps = {
  name: string;
  label?: string;
  value?: number | any;
  itemTypeId?: number;
  onChange?: (e: ValueToSet) => void;
  disabled?: boolean;
  focus?: boolean;
  mbZero?: boolean;
};

const Int = ({ name, label, onChange, itemTypeId, value, disabled, focus, mbZero }: IntProps) => {
  const inputRef = useRef();
  useEffect(() => {
    if (focus) {
      //@ts-ignore
      inputRef.current?.handleFocus();
    }
  }, [focus]);

  return (
    <Input
      ref={inputRef}
      mbZero={mbZero}
      name={name}
      value={value}
      label={label}
      type={InputTypes.NUMBER}
      disabled={disabled}
      isUsedInBackOffice={true}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange({ name: event.target.name, value: Number(event.target.value), itemTypeId } as ValueToSet);
      }}
    />
  );
};

export default Int;
