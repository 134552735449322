import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

const ConsumptionPoints = (props: { list: any; getData: any; deleteIndex: any }) => {
  return (
    <div>
      {props.list.length > 0 && (
        <div className="m-3 general-table-container rss">
          <table className="w-100">
            <thead>
              <tr>
                <th>Nume și prenume</th>
                <th>POD</th>
                <th>CLC</th>
                <th>Adresă loc consum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.list.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    <span>{item.clientName || '-'}</span>
                  </td>
                  <td>
                    <span>{item.pod || '-'}</span>
                  </td>
                  <td>
                    <span>{item.consumptionPointCode || '-'}</span>
                  </td>
                  <td>
                    <span>{item.address && item.address.endsWith(', 0') ? item.address.slice(0, -3) : item.address || '-'}</span>
                  </td>
                  <td>
                    {item.status === 1 && (
                      <Button className="btn-wide" onClick={() => props.getData(item.consumptionPointId, item.consumptionPointCode)}>
                        Transmite index
                      </Button>
                    )}
                    {item.status === 2 && (
                      <Button
                        className="mt-1"
                        onClick={() => props.getData(item.consumptionPointId, item.consumptionPointCode)}
                        style={{ marginRight: '8px' }}
                      >
                        <FontAwesomeIcon icon={faPen} /> Editează index transmis
                      </Button>
                    )}
                    {item.status === 2 && (
                      <Button className="mt-1" onClick={() => props.deleteIndex(item.consumptionPointId, item.consumptionPointCode)}>
                        <FontAwesomeIcon icon={faTrash} /> Șterge index transmis
                      </Button>
                    )}
                    {item.status === 3 && <span>Autocitire transmisă</span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ConsumptionPoints;