import React, { useState, useEffect } from 'react';
import { CheckBox } from '../CheckBox';

type DropdownOptionType = {
  id: string | number;
  name: string;
  disabled?: boolean;
  extraPropertyName?: string;
  extraPropertyValue?: string | number;
};

type MultiselectProps = {
  options: DropdownOptionType[];
  disabled?: boolean;
  height?: number;
  placeholder?: string;
  name?: string;
  label?: string;
  onChange: (option: any) => void;
  error?: string;
  mbZero?: boolean;
  initialSelectedOptions?: DropdownOptionType[];
};

const MultiselectCheckBox = ({ options, disabled, height, placeholder, onChange, ...props }: MultiselectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<DropdownOptionType[]>([]);

  useEffect(() => {
    if (props.initialSelectedOptions) {
      setSelectedOptions(props.initialSelectedOptions);
    }
  }, [props.initialSelectedOptions]);

  const handleSelect = (option: DropdownOptionType) => {
    let updatedOptions;
    if (!selectedOptions.find((selected) => selected.id === option.id)) {
      updatedOptions = [...selectedOptions, option];
      setSelectedOptions(updatedOptions);
    } else {
      updatedOptions = selectedOptions.filter((selected) => selected.id !== option.id);
      setSelectedOptions(updatedOptions);
    }
    onChange(updatedOptions);
  };

  return (
    <>
      {options.map((option, index) => {
        return (
          <div className="ms-2 mt-1" key={index}>
            <CheckBox
              subtitle={option.name}
              checked={
                !!selectedOptions.find((op: any) => {
                  return op.name === option.name;
                })
              }
              onClick={(event) => {
                handleSelect(option);
              }}
            />
            { 
              //@ts-ignore
              option.details && option.details.map((detail: any) => {
                return <div className="mb-1" dangerouslySetInnerHTML={{ __html: detail }}/>
              })
            }
          </div>
        );
      })}
    </>
  );
};

export default MultiselectCheckBox;
