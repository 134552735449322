import { Fragment, useEffect, useState } from 'react';

import ModalComponent from '@components/dev/Components/Modal/Modal';
import { ActionType, DataTypes, DetailActionResponse, Field, IntegerTypes, ModalSize } from '@type/backOfficeModule';
import { Col, Row, Table } from 'react-bootstrap';
import useBoHelpers from '../Hooks/useBoHelpers';
import { BackOfficeContext, BackOfficeContextType, InputValues } from '@contexts/backoffice-context';
import React from 'react';

type ActionModalDetailsProps = {
  action: ActionType;
  isEditing: boolean;
  setIsEditing: (val: boolean) => void;
  detailActionValues: any;
  item: any;
};

const ActionModalDetails = (props: ActionModalDetailsProps) => {
  const { action, isEditing, detailActionValues, item } = props;
  const { config } = React.useContext(BackOfficeContext) as BackOfficeContextType;
  const [values, setValues] = useState<InputValues>({} as InputValues);

  const { setIsEditing } = props;
  const { getModalSize, getColumnOrDetailsValue } = useBoHelpers();

  useEffect(() => {
    if (isEditing === false) {
      setValues({} as InputValues);
    } else {
      if (config) {
        let allFields: InputValues = {};
        action?.formGroups?.forEach((group) => {
          let fields = group?.fields?.reduce(
            (o, current) => ({
              ...o,
              [current.name]:
                current.dataTypeId === DataTypes.Bool
                  ? item[current.name.charAt(0).toLowerCase() + current.name.slice(1)] === 'True' ||
                    item[current.name.charAt(0).toLowerCase() + current.name.slice(1)] === true
                    ? true
                    : false
                  : item[current.name.charAt(0).toLowerCase() + current.name.slice(1)]
                  ? IntegerTypes.includes(current.dataTypeId)
                    ? Number(item[current.name.charAt(0).toLowerCase() + current.name.slice(1)])
                    : item[current.name.charAt(0).toLowerCase() + current.name.slice(1)]
                  : current.value
                  ? IntegerTypes.includes(current.dataTypeId)
                    ? Number(current.value)
                    : current.value
                  : current.dataTypeId === DataTypes.Base64FileList
                  ? []
                  : ''
            }),
            {} as InputValues
          );
          allFields = { ...allFields, ...fields };
        });

        //set values for field childs
        action?.formGroups?.forEach((group) => {
          let fields: { [key: string]: any } = {};

          group?.fields?.forEach((field: Field) => {
            if (field.itemsConfiguration?.cascadeChild) {
              let child = field.itemsConfiguration?.cascadeChild;
              while (child) {
                fields[child.name] = child.value
                  ? IntegerTypes.includes(field.dataTypeId)
                    ? Number(child.value)
                    : child.value
                  : IntegerTypes.includes(field.dataTypeId)
                  ? Number(item[child.name.charAt(0).toLowerCase() + child.name.slice(1)])
                  : item[child.name.charAt(0).toLowerCase() + child.name.slice(1)];

                if (child.itemsConfiguration?.cascadeChild) {
                  child = child.itemsConfiguration?.cascadeChild;
                } else {
                  //@ts-ignore
                  child = null;
                }
              }
            }
          });
          allFields = { ...allFields, ...fields };
        });

        setValues({
          ...allFields,
          [config.identityColumnName]: item[config.identityColumnName.charAt(0).toLowerCase() + config.identityColumnName.slice(1)]
        });
      }
    }
  }, [isEditing]);

  const renderDetails = (field: Field) => {
    let key = field.name.charAt(0).toLowerCase() + field.name.slice(1);
    let keyV = field.name;

    let showRowIndex = field.showRowIndex ? field.showRowIndex : false;

    if (Array.isArray(detailActionValues)) {
      if (field.dataTypeId == DataTypes.GroupedFieldsList) {
        return (
          <div>
            {detailActionValues.map((detailObject: DetailActionResponse, index: number) => {
              return (
                <Fragment>
                  <Row className="my-2">
                    {showRowIndex && (
                      <Col sm={1} lg={1} md={1} xs={1} className="d-flex justify-content-center">
                        <h4 className="mb-0">{index + 1}</h4>
                      </Col>
                    )}

                    <Col className={`${showRowIndex ? 'p-0' : ''}`}>
                      {field.items?.map((item: any) => {
                        const value = getColumnOrDetailsValue(item, detailObject);
                        if (item.hideIfEmptyValue && !value) return;
                        return (
                          <div>
                            <span>
                              <strong>{item.displayName}:</strong>
                            </span>{' '}
                            {value}
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                  <hr />
                </Fragment>
              );
            })}
          </div>
        );
      } else if (field.dataTypeId == DataTypes.TableFieldsList) {
        return (
          <div style={{ overflow: 'scroll' }}>
            <div className="general-table-container">
              <Table>
                <thead>
                  <tr>
                    {showRowIndex && <th>#</th>}
                    {field.items?.map((item: any) => {
                      return <th>{item.displayName}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {detailActionValues.map((detailObject: DetailActionResponse, index: number) => {
                    return (
                      <tr>
                        {showRowIndex && (
                          <td>
                            <span>{index + 1}</span>
                          </td>
                        )}
                        {field.items?.map((item: any) => {
                          const value = getColumnOrDetailsValue(item, detailObject);
                          if (item.hideIfEmptyValue && !value) return;
                          return (
                            <td>
                              <span>{value}</span>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        );
      }
    } else {
      switch (field.dataTypeId) {
        case DataTypes.ReadonlyJson:
          var obj = '';
          var parsed = '';
          if (!detailActionValues[key] && !values[keyV]) {
            return <p>Fara detalii obiect!</p>;
          } else if (detailActionValues[key]) {
            try {
              obj = JSON.parse(detailActionValues[key]);
              parsed = JSON.stringify(obj, undefined, 2);
            } catch (E) {
              return (
                <div>
                  <h5>Eroare la parsare JSON!</h5>
                  <h6>Conținut inițial:</h6>
                  <p>{detailActionValues[key]}</p>
                </div>
              );
            }
          } else if (values[keyV]) {
            try {
              obj = JSON.parse(values[keyV] as string);
              parsed = JSON.stringify(obj, undefined, 2);
            } catch (E) {
              return (
                <div>
                  <h5>Eroare la parsare JSON!</h5>
                  <h6>Conținut inițial:</h6>
                  <p>{values[keyV]}</p>
                </div>
              );
            }
          }

          return (
            <div>
              <h5>{field.displayName}</h5>
              <pre>{parsed}</pre>
            </div>
          );
        case DataTypes.ReadonlyString:
          if (!detailActionValues[key] && !values[keyV]) {
            return <p>Fara detalii mesaj!</p>;
          } else if (detailActionValues[key]) {
            return (
              <div>
                <h5>{field.displayName}</h5>
                {detailActionValues[key]}
              </div>
            );
          } else if (values[keyV]) {
            return (
              <div>
                <h5>{field.displayName}</h5>
                {values[keyV]}
              </div>
            );
          } else {
            return '-';
          }
        default:
          const value = getColumnOrDetailsValue(field, detailActionValues);

          if (field.hideIfEmptyValue && !value) return;
          return (
            <div>
              <h5>{field.displayName}</h5>
              <div>{value}</div>
            </div>
          );
      }
    }

    return null;
  };

  return (
    <>
      <ModalComponent
        title={action.displayName}
        show={isEditing}
        className="backoffice-modal"
        setShow={(state: boolean) => {
          setIsEditing(!isEditing);
        }}
        fullScreen={action.modalSize && action.modalSize == ModalSize.FullScreen ? true : 'md-down'}
        size={action.modalSize ? getModalSize(action.modalSize) : 'lg'}
      >
        <div className="mt-2">
          {Object.keys(detailActionValues).length > 0 || true ? (
            <Row>
              {action.formGroups?.map((group, index: number) => {
                return (
                  group.fields &&
                  group.fields?.map((field, index: number) => {
                    return (
                      <Col key={index} md={12} className={'mt-1'}>
                        {renderDetails(field as Field)}
                      </Col>
                    );
                  })
                );
              })}
            </Row>
          ) : (
            <Row>
              <Col>
                <h4>Loading...</h4>
              </Col>
            </Row>
          )}
        </div>
      </ModalComponent>
    </>
  );
};

export default ActionModalDetails;
