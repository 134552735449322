import { CustomGet, QueryParams } from '@api/backOffice/fetchResults';
import { Field, OptionsForField } from '@type/backOfficeModule';
import { useEffect, useState } from 'react';
import { InputValues } from '@contexts/backoffice-context';

export type UseFieldOptionsProps = {
  action?: any;
  isEditing?: boolean;
  isRowAction?: boolean;
  item?: any;
  setValues?: any;
  isUsedInFilersArea?: boolean;
  filters?: Field[];
};

export type GetOptionsFromApiPayload = {
  fieldName: string;
  keyFieldName: string;
  url: string | any;
  childUrl: string | any;
  childName: string;
  parentName: string;
  childUrlQuerryParameterName: string;
  urlParams?: QueryParams[];
};

export const useFieldOptions = (props: UseFieldOptionsProps) => {
  const { action, isEditing, isRowAction, item, setValues, isUsedInFilersArea, filters } = props;
  const [optionsForField, setOptionsForField] = useState<OptionsForField[]>([] as OptionsForField[]);

  useEffect(() => {
    if (isUsedInFilersArea && filters) {
      filters.forEach((filter: Field) => {
        if (filter.itemsConfiguration && !filter.itemsConfiguration.staticData) {
          const url = filter.itemsConfiguration.itemsDefinition?.url;
          const keyFieldName = filter.itemsConfiguration?.keyFieldName
            ? filter.itemsConfiguration?.keyFieldName.charAt(0).toLocaleLowerCase() + filter.itemsConfiguration?.keyFieldName.slice(1)
            : 'id';
          let childName;
          let childUrl;
          let childUrlQuerryParamName = '';
          if (filter.itemsConfiguration.cascadeChild) {
            const child = filter.itemsConfiguration.cascadeChild;
            if (child.itemsConfiguration && !child.itemsConfiguration.staticData) {
              childName = child.name;
              childUrl = child.itemsConfiguration.itemsDefinition?.url;
              childUrlQuerryParamName =
                (child.itemsConfiguration.itemsDefinition?.queryStringSourceParameterName ?? '').charAt(0).toLowerCase() +
                (child.itemsConfiguration.itemsDefinition?.queryStringSourceParameterName ?? '').slice(1);
            }
          }

          getOptionsFromApi({
            fieldName: filter.name,
            keyFieldName: keyFieldName,
            url: url,
            childUrl: childUrl,
            childName: childName as string,
            parentName: '',
            childUrlQuerryParameterName: childUrlQuerryParamName
          } as GetOptionsFromApiPayload);
        }
      });
    } else if (isEditing && action.formGroups) {
      action.formGroups.forEach((formGroup: any) => {
        if (formGroup.fields) {
          formGroup.fields.forEach((field: Field) => {
            if (field.itemsConfiguration && !field.itemsConfiguration.staticData) {
              const url = field.itemsConfiguration.itemsDefinition?.url;
              const keyFieldName = field.itemsConfiguration?.keyFieldName
                ? field.itemsConfiguration?.keyFieldName.charAt(0).toLocaleLowerCase() + field.itemsConfiguration?.keyFieldName.slice(1)
                : 'id';
              let childName;
              let childUrl;
              let childUrlQuerryParamName = '';
              let extraPropertiesToSendInActionModel = field.itemsConfiguration.extraPropertiesToSendInActionModel || [];
              if (field.itemsConfiguration.cascadeChild) {
                const child = field.itemsConfiguration.cascadeChild;
                if (child.itemsConfiguration && !child.itemsConfiguration.staticData) {
                  childName = child.name;
                  childUrl = child.itemsConfiguration.itemsDefinition?.url;
                  childUrlQuerryParamName =
                    (child.itemsConfiguration.itemsDefinition?.queryStringSourceParameterName ?? '').charAt(0).toLowerCase() +
                    (child.itemsConfiguration.itemsDefinition?.queryStringSourceParameterName ?? '').slice(1);
                }
                if (
                  child?.itemsConfiguration?.extraPropertiesToSendInActionModel &&
                  child.itemsConfiguration.extraPropertiesToSendInActionModel[0]
                ) {
                  extraPropertiesToSendInActionModel = [
                    ...extraPropertiesToSendInActionModel,
                    ...child.itemsConfiguration.extraPropertiesToSendInActionModel
                  ];
                }
              }

              if (extraPropertiesToSendInActionModel) {
                let extraProperties = {} as InputValues;
                extraPropertiesToSendInActionModel.forEach((property: string) => {
                  extraProperties[property] = '';
                });
                setValues((prevValues: any) => {
                  return {
                    ...prevValues,
                    ...extraProperties
                  };
                });
              }

              let params;
              if (isRowAction) {
                params = [
                  {
                    name: childUrlQuerryParamName as string,
                    value: item[keyFieldName]
                  }
                ] as QueryParams[];
              }

              if (childUrlQuerryParamName) {
                getOptionsFromApi({
                  fieldName: field.name,
                  keyFieldName: keyFieldName,
                  url: url,
                  childUrl: childUrl,
                  childName: childName as string,
                  parentName: '',
                  childUrlQuerryParameterName: childUrlQuerryParamName,
                  urlParams: undefined
                } as GetOptionsFromApiPayload);
              } else {
                getOptionsFromApi({
                  fieldName: field.name,
                  keyFieldName: keyFieldName,
                  url: url,
                  childUrl: childUrl,
                  childName: childName as string,
                  parentName: '',
                  childUrlQuerryParameterName: '',
                  urlParams: params
                } as GetOptionsFromApiPayload);
              }
            }
          });
        }
      });
    }
  }, [action, isEditing, isUsedInFilersArea, filters]);

  const getOptionsFromApi = async ({
    fieldName,
    keyFieldName,
    url,
    childUrl,
    childName,
    parentName,
    childUrlQuerryParameterName,
    urlParams
  }: GetOptionsFromApiPayload) => {
    let options = await CustomGet(url, urlParams);

    setOptionsForField((preProps) => {
      let existentFieldIndex = preProps.findIndex((p: OptionsForField) => p.field === fieldName);

      // Check if the options are different to avoid unnecessary re-renders
      const currentOptions = existentFieldIndex >= 0 ? preProps[existentFieldIndex].options : [];
      const areOptionsSame = JSON.stringify(currentOptions) === JSON.stringify(options);
      if (areOptionsSame) {
        return preProps; // Return the current state if options are the same
      }

      if (existentFieldIndex >= 0) {
        return [
          ...preProps.slice(0, existentFieldIndex),
          Object.assign({}, preProps[existentFieldIndex], {
            field: fieldName,
            child: childName,
            keyFieldName: keyFieldName,
            childUrl: childUrl,
            childUrlQuerryParameterName: childUrlQuerryParameterName,
            parent: parentName,
            parentValue: urlParams ? urlParams[0].value : '',
            options: [...options]
          }),
          ...preProps.slice(existentFieldIndex + 1)
        ];
      } else {
        return [
          ...preProps,
          {
            field: fieldName,
            child: childName,
            keyFieldName: keyFieldName,
            childUrl: childUrl,
            childUrlQuerryParameterName: childUrlQuerryParameterName,
            parent: parentName,
            parentValue: urlParams ? urlParams[0].value : '',
            options: [...options]
          }
        ];
      }
    });
  };

  return { optionsForField, getOptionsFromApi };
};
