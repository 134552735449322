import { Input } from '@components/common/Input';
import { ValueToSet } from '@type/backOfficeModule';
import React, { useEffect, useRef } from 'react';

type StringProps = {
  name: string;
  label?: string;
  value?: string;
  itemTypeId?: number;
  onChange?: (e: ValueToSet) => void;
  disabled?: boolean;
  focus?: boolean;
  mbZero?: boolean;
};

const String = ({ name, label, onChange, itemTypeId, value, disabled, focus, mbZero }: StringProps) => {
  const inputRef = useRef();
  useEffect(() => {
    if (focus) {
      //@ts-ignore
      inputRef.current?.handleFocus();
    }
  }, [focus]);

  return (
    <Input
      ref={inputRef}
      name={name}
      value={value}
      label={label}
      disabled={disabled}
      isUsedInBackOffice={true}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange({ name: event.target.name, value: event.target.value, itemTypeId } as ValueToSet);
      }}
      mbZero={mbZero}
    />
  );
};

export default String;
